@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-user-select: none;
  user-select: none;
}

.card-img{
	width:90% !important;
}

.black_button_default {
  background-color: rgba(50, 51, 56, 1) !important;
  color: white !important;
  border: 1px solid rgba(50, 51, 56, 1) !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.black_button_default:focus{
  box-shadow: unset !important;
}

.secondary_button_default {
  background-color: white !important;
  color: rgba(50, 51, 56, 1) !important;
  border: 1px solid #C5C7CF !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.secondary_button_default:focus{
  box-shadow: unset !important;
}

.secondary_button_ghost {
  background-color: transparent !important;
  color: rgba(50, 51, 56, 1) !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: unset;
  padding: 4px 8px !important;
  cursor: pointer;
}

.secondary_button_ghost:focus{
  box-shadow: unset !important;
}


.primary_button_default {
  background-color: #0078ff !important;
  color: white !important;
  border: 1px solid #4597F7 !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.primary_button_default:focus{
  box-shadow: unset !important;
}

.primary_button_ghost {
  background-color: transparent !important;
  color: #0078ff !important;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: unset;
  padding: 4px 8px !important;
  cursor: pointer;
}

.primary_button_ghost:focus{
  box-shadow: unset !important;
}

.red_button_default {
  background-color: rgba(249, 218, 222, 1) !important;
  color: rgba(255, 82, 71, 1) !important;
  border: 1px solid rgba(255, 82, 71, 1) !important;
  padding: 0px 16px !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px !important;
  font-size: 14px !important;
  box-shadow: unset;
}

.red_button_default:focus{
  box-shadow: unset !important;
}

.red_button_default_ghost {
  background-color: transparent !important;
  color: rgba(255, 82, 71, 1) !important;
  box-shadow: unset;
  cursor: pointer;
}

.zero_padding {
  padding: 0px !important;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_property{
  display: flex;
  align-items: center;
}

.flex_property_wrap{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flex_column{
  display: flex;
  flex-direction: column;
}

.hover_left_menu:hover {
  background-color: #F5F6F8;
  border-radius: 10px;
}

.hover_toolbar:hover {
  background-color: var(--secondary-background-design-navbar, #a7a8b340);
  /* border-radius: 10px; */
}

.toolbar_active {
  background-color: #E6E9EE;
  /* border-radius: 10px; */
}

.left16 {
  left: 16px !important;
}

.left44 {
  left: -44px !important;
}

.global_modal_header{
  height:40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E6E9EE;
  background-color: white;
  color:black;
  font-weight: 700;
  font-size: 16px;
}

.global_modal_footer{
  height:50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color:#FFFFFF;
}

#tooltip1
{
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  
  display: inline-block;
}

#tooltip1.right
{
  margin-left: 5px;
}

#tooltip1.left
{
  margin-left: -5px;
}

#tooltip1.top
{
  margin-top: -5px;
}

#tooltip1.bottom
{
  margin-top: 30px;
}

#tooltip1 .tooltip-arrow
{
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

#tooltip1.right .tooltip-arrow
{
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}



#tooltip1.top .tooltip-arrow
{
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

#tooltip1.left .tooltip-arrow
{
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  right: -5px;
  left: auto;
}

#tooltip1.bottom .tooltip-arrow
{
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

#tooltip1 .tooltip-arrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip1 .tooltip-label
{
  max-width: 500px;
  height: 40px;
  min-width:150px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.display_onhover:hover{
  box-shadow: inset 0 -1px 0 0 #F2F4F6;
  background-color: rgba(24, 144, 255, 0.06) !important;
}

.invisible1{
	visibility: hidden;
}

.display_onhover:hover .x1 .invisible1{
display:flex;
visibility:visible;
color: #1890ff;
}

.delete_button{
  border: 1px #f5594e solid;
  padding: 6px 8px;
  border-radius: 4px;
}

.delete_button:hover{
  opacity: 0.7;
}

.loader {
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 60%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0,0,0,0.2);
  position: relative;
 }
 
 .loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 3s ease-in-out infinite;
  ;
 }
 
 @keyframes moving {
  50% {
   width: 100%;
  }
 
  100% {
   width: 0;
   right: 0;
   left: unset;
  }
 }

 :root {
  --panel_tree_container_background: #F5F6F8;
  --bottom_status_bar_offset_on_logo: 10px;
  --navbar_height_design: 52px;
  --ribbon_tabs_height: 24px;
  --toolbar_height: 60px;
  --panel_width: 270px;
  --blue6: #0078ff;
  --red_default: #FF5247;

  --inf-canvas-height: calc( 100vh - var(--navbar_height_design, 52px) - var(--ribbon_tabs_height, 24px) - var(--toolbar_height, 60px) );

  --inf-bottom-carousel-height: 170px;
  --inf-bottom-carousel-nav-height: 42px;
  --inf-bottom-carousel-card-height: 74px;

  --infurnia-navbar-height: 3.25rem;
  --infurnia-sidebar-width: 15rem;

  --inf-highlight-background-color: #788394;

 }

.mes_design_edit_mode{
  --inf-highlight-background-color: #4C4D52;
 }

 .js-c{
  justify-content: center;
 }

 .js-s{
  justify-content: flex-start;
 }

 .js-e{
  justify-content: flex-end;
 }

 .js-sa{
  justify-content: space-around;
 }

 .js-sb{
  justify-content: space-between;
 }

 .js-se{
  justify-content: space-evenly;
 }
 
 .modal-body{
  background-color: #F5F6F8;
 }

 .modal-footer{
  box-shadow: 0px -1px 6px 0px #0000000D;
 }

 .modal-header{
  height: 48px;
  align-items: center !important;
 }
 
 .scale_120 {
  transition: 0.3s ease-in;
}

.scale_120:hover {
  transform: scale(1.20);
}

.drop_hover_2 {
  transition: 0.3s ease-in;
}

.drop_hover_2:hover {
  transform: translateY(2px);
}

 .trail_extension{
  background-color: #F9DADE;
  color: #FF5247;
 }

 .common_input_styling{
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 32px;
  padding: 0px 12px;
 }

 .cp{
  cursor: pointer;
 }
 .dropdown-menu.show{
  padding: 4px;
 }

 .dropdown-menu.show .dropdown-item{
  /* color: #676878; */
  font-size: 14px;
 }
 .standard_input{
	width: 100%;
	height: 32px;
	font-size: 14px;
  }

.free_view_dropdown1 {
  top: -28px !important;
}

.free_view_dropdown2 {
  top: -28px !important;
}

.free_view_dropdown3 {
  top: -28px !important;
}

.free_view_dropdown4 {
  top: -4px !important;
}

  .all_views_div_inner ::-webkit-scrollbar{
	height: 0px !important;
  }

  .all_views_div_outer ::-webkit-scrollbar{
	height: 0px !important;
  }

.item_invisible{
  visibility: hidden;
}

.visible_on_hover:hover .item_invisible{
  visibility: visible;
}

.infurnia-avatar {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin: 2px 0px;
  color: rgba(0, 120, 255, 1);
  background-color: rgba(0, 120, 255, 0.1);
  border: 1px solid white;
  justify-content: center;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500
}

.mes_panel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	font-size: 12px;
	cursor: pointer;
	border-radius: 8px;
	--tw-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
    --tw-shadow-colored: 0 2px 12px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.mes_panel:hover{
	--tw-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    --tw-shadow-colored: 0 2px 12px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.inf_loading_background {
  background: url(/resources/icons/loading_animation.gif) center center no-repeat;
  background-color: white;
}

.flex-1-mw {
    flex: 1 1;
    min-width: 1px;
}

.flex-2-mw {
    flex: 2 1;
    min-width: 1px;
}

.flex-3-mw {
    flex: 3 1;
    min-width: 1px;
}

.flex-4-mw {
    flex: 4 1;
    min-width: 1px;
}

.flex-5-mw {
    flex: 5 1;
    min-width: 1px;
}

.flex-6-mw {
    flex: 6 1;
    min-width: 1px;
}

.flex-7-mw {
    flex: 7 1;
    min-width: 1px;
}

.flex-8-mw {
    flex: 8 1;
    min-width: 1px;
}

.flex-9-mw {
    flex: 9 1;
    min-width: 1px;
}

.flex-10-mw {
    flex: 10 1;
    min-width: 1px;
}

.flex-11-mw {
    flex: 11 1;
    min-width: 1px;
}

.flex-12-mw {
    flex: 12 1;
    min-width: 1px;
}

.flex-1-mh {
    flex: 1 1;
    min-height: 1px;
}

.flex-2-mh {
    flex: 2 1;
    min-height: 1px;
}

.flex-3-mh {
    flex: 3 1;
    min-height: 1px;
}

.flex-4-mh {
    flex: 4 1;
    min-height: 1px;
}

.flex-5-mh {
    flex: 5 1;
    min-height: 1px;
}

.flex-6-mh {
    flex: 6 1;
    min-height: 1px;
}

.flex-7-mh {
    flex: 7 1;
    min-height: 1px;
}

.flex-8-mh {
    flex: 8 1;
    min-height: 1px;
}

.flex-9-mh {
    flex: 9 1;
    min-height: 1px;
}

.flex-10-mh {
    flex: 10 1;
    min-height: 1px;
}

.flex-11-mh {
    flex: 11 1;
    min-height: 1px;
}

.flex-12-mh {
    flex: 12 1;
    min-height: 1px;
}

/* Border */
.inf-border-light{
    border: 1px solid var(--very-light-border-color);
}

.inf-border{
    border: 1px solid var(--light-border-color);
}

.inf-border-dark{
    border: 1px solid var(--dark-border-color);
}
:root {
    /* Element Specific Definitions */
    /* Space Definitions */
    --inf-flex-grid-gap: 1rem;

    /* Color Definitions */
    --layer-main-bg: #FFFFFF;
    --layer-secondary-bg: #F5F6F8;
    --layer-main-text-color: #676878;
    --inf-warning-text-color: #FF5247;
    
    --dark-border-color: var(--inf-theme-gray-400);
    --light-border-color: var(--inf-theme-gray-300);
    --very-light-border-color: var(--inf-theme-gray-200);

    /* Theme Defininitions */
    --inf-theme-gray-100: #FFFFFF;
    --inf-theme-gray-200: #F5F6F8;
    --inf-theme-gray-300: #E6E9EE;
    --inf-theme-gray-400: #C5C7CF;
    --inf-theme-gray-500: #A7A8B3;
    --inf-theme-gray-600: #676878;
    --inf-theme-gray-700: #323338;
    --inf-theme-gray-800: #202126;
    --inf-theme-gray-900: #A7A8B2;

    /* Primary Highlight Colors */
    --inf-primary-blue: #0078ff;
    --inf-primary-blue-hover-color: #E5F1FF;

    /* Screen Breakpoint Definitions */
    --inf-breakpoint-sm: 640px;
    --inf-breakpoint-md: 768px;
    --inf-breakpoint-lg: 1024px;
    --inf-breakpoint-xl: 1280px;
    --inf-breakpoint-2xl: 1536px;

    /* Spacing Definitions */
    --spacing-0: 0px;
    --spacing-0p5: 0.125rem;
    --spacing-1: 0.25rem;
    --spacing-1p5: 0.375rem;
    --spacing-2: 0.5rem;
    --spacing-2p5: 0.625rem;
    --spacing-3: 0.75rem;
    --spacing-3p5: 0.875rem;
    --spacing-4: 1rem;
    --spacing-5: 1.25rem;
    --spacing-6: 1.5rem;
    --spacing-7: 1.75rem;
    --spacing-8: 2rem;
    --spacing-9: 2.25rem;
    --spacing-10: 2.5rem;
    --spacing-11: 2.75rem;
    --spacing-12: 3rem;
    --spacing-14: 3.5rem;
    --spacing-16: 4rem;
    --spacing-20: 5rem;
    --spacing-24: 6rem;
    --spacing-28: 7rem;
    --spacing-32: 8rem;
    --spacing-36: 9rem;
    --spacing-40: 10rem;
    --spacing-44: 11rem;
    --spacing-48: 12rem;
    --spacing-52: 13rem;
    --spacing-56: 14rem;
    --spacing-60: 15rem;
    --spacing-64: 16rem;
    --spacing-72: 18rem;
    --spacing-80: 20rem;
    --spacing-96: 24rem,
}

/* Margin classes based on spacing variables */
.inf-m-0 {
    margin: var(--spacing-0);
}

.inf-m-0p5 {
    margin: var(--spacing-0p5);
}

.inf-m-1 {
    margin: var(--spacing-1);
}

.inf-m-1p5 {
    margin: var(--spacing-1p5);
}

.inf-m-2 {
    margin: var(--spacing-2);
}

.inf-m-2p5 {
    margin: var(--spacing-2p5);
}

.inf-m-3 {
    margin: var(--spacing-3);
}

.inf-m-3p5 {
    margin: var(--spacing-3p5);
}

.inf-m-4 {
    margin: var(--spacing-4);
}

.inf-m-5 {
    margin: var(--spacing-5);
}

.inf-m-6 {
    margin: var(--spacing-6);
}

.inf-m-7 {
    margin: var(--spacing-7);
}

.inf-m-8 {
    margin: var(--spacing-8);
}

.inf-m-9 {
    margin: var(--spacing-9);
}

.inf-m-10 {
    margin: var(--spacing-10);
}

.inf-m-11 {
    margin: var(--spacing-11);
}

.inf-m-12 {
    margin: var(--spacing-12);
}

.inf-m-14 {
    margin: var(--spacing-14);
}

.inf-m-16 {
    margin: var(--spacing-16);
}

.inf-m-20 {
    margin: var(--spacing-20);
}

.inf-m-24 {
    margin: var(--spacing-24);
}

.inf-m-28 {
    margin: var(--spacing-28);
}

.inf-m-32 {
    margin: var(--spacing-32);
}

.inf-m-36 {
    margin: var(--spacing-36);
}

.inf-m-40 {
    margin: var(--spacing-40);
}

.inf-m-44 {
    margin: var(--spacing-44);
}

.inf-m-48 {
    margin: var(--spacing-48);
}

.inf-m-52 {
    margin: var(--spacing-52);
}

.inf-m-56 {
    margin: var(--spacing-56);
}

.inf-m-60 {
    margin: var(--spacing-60);
}

.inf-m-64 {
    margin: var(--spacing-64);
}

.inf-m-72 {
    margin: var(--spacing-72);
}

.inf-m-80 {
    margin: var(--spacing-80);
}

.inf-m-96 {
    margin: var(--spacing-96);
}


/* Margin-left classes based on spacing variables */
.inf-ml-0 {
    margin-left: var(--spacing-0);
}

.inf-ml-0p5 {
    margin-left: var(--spacing-0p5);
}

.inf-ml-1 {
    margin-left: var(--spacing-1);
}

.inf-ml-1p5 {
    margin-left: var(--spacing-1p5);
}

.inf-ml-2 {
    margin-left: var(--spacing-2);
}

.inf-ml-2p5 {
    margin-left: var(--spacing-2p5);
}

.inf-ml-3 {
    margin-left: var(--spacing-3);
}

.inf-ml-3p5 {
    margin-left: var(--spacing-3p5);
}

.inf-ml-4 {
    margin-left: var(--spacing-4);
}

.inf-ml-5 {
    margin-left: var(--spacing-5);
}

.inf-ml-6 {
    margin-left: var(--spacing-6);
}

.inf-ml-7 {
    margin-left: var(--spacing-7);
}

.inf-ml-8 {
    margin-left: var(--spacing-8);
}

.inf-ml-9 {
    margin-left: var(--spacing-9);
}

.inf-ml-10 {
    margin-left: var(--spacing-10);
}

.inf-ml-11 {
    margin-left: var(--spacing-11);
}

.inf-ml-12 {
    margin-left: var(--spacing-12);
}

.inf-ml-14 {
    margin-left: var(--spacing-14);
}

.inf-ml-16 {
    margin-left: var(--spacing-16);
}

.inf-ml-20 {
    margin-left: var(--spacing-20);
}

.inf-ml-24 {
    margin-left: var(--spacing-24);
}

.inf-ml-28 {
    margin-left: var(--spacing-28);
}

.inf-ml-32 {
    margin-left: var(--spacing-32);
}

.inf-ml-36 {
    margin-left: var(--spacing-36);
}

.inf-ml-40 {
    margin-left: var(--spacing-40);
}

.inf-ml-44 {
    margin-left: var(--spacing-44);
}

.inf-ml-48 {
    margin-left: var(--spacing-48);
}

.inf-ml-52 {
    margin-left: var(--spacing-52);
}

.inf-ml-56 {
    margin-left: var(--spacing-56);
}

.inf-ml-60 {
    margin-left: var(--spacing-60);
}

.inf-ml-64 {
    margin-left: var(--spacing-64);
}

.inf-ml-72 {
    margin-left: var(--spacing-72);
}

.inf-ml-80 {
    margin-left: var(--spacing-80);
}

.inf-ml-96 {
    margin-left: var(--spacing-96);
}

.inf-ml-auto {
    margin-left: auto;
}

/* Margin-right classes based on spacing variables */
.inf-mr-0 {
    margin-right: var(--spacing-0);
}

.inf-mr-0p5 {
    margin-right: var(--spacing-0p5);
}

.inf-mr-1 {
    margin-right: var(--spacing-1);
}

.inf-mr-1p5 {
    margin-right: var(--spacing-1p5);
}

.inf-mr-2 {
    margin-right: var(--spacing-2);
}

.inf-mr-2p5 {
    margin-right: var(--spacing-2p5);
}

.inf-mr-3 {
    margin-right: var(--spacing-3);
}

.inf-mr-3p5 {
    margin-right: var(--spacing-3p5);
}

.inf-mr-4 {
    margin-right: var(--spacing-4);
}

.inf-mr-5 {
    margin-right: var(--spacing-5);
}

.inf-mr-6 {
    margin-right: var(--spacing-6);
}

.inf-mr-7 {
    margin-right: var(--spacing-7);
}

.inf-mr-8 {
    margin-right: var(--spacing-8);
}

.inf-mr-9 {
    margin-right: var(--spacing-9);
}

.inf-mr-10 {
    margin-right: var(--spacing-10);
}

.inf-mr-11 {
    margin-right: var(--spacing-11);
}

.inf-mr-12 {
    margin-right: var(--spacing-12);
}

.inf-mr-14 {
    margin-right: var(--spacing-14);
}

.inf-mr-16 {
    margin-right: var(--spacing-16);
}

.inf-mr-20 {
    margin-right: var(--spacing-20);
}

.inf-mr-24 {
    margin-right: var(--spacing-24);
}

.inf-mr-28 {
    margin-right: var(--spacing-28);
}

.inf-mr-32 {
    margin-right: var(--spacing-32);
}

.inf-mr-36 {
    margin-right: var(--spacing-36);
}

.inf-mr-40 {
    margin-right: var(--spacing-40);
}

.inf-mr-44 {
    margin-right: var(--spacing-44);
}

.inf-mr-48 {
    margin-right: var(--spacing-48);
}

.inf-mr-52 {
    margin-right: var(--spacing-52);
}

.inf-mr-56 {
    margin-right: var(--spacing-56);
}

.inf-mr-60 {
    margin-right: var(--spacing-60);
}

.inf-mr-64 {
    margin-right: var(--spacing-64);
}

.inf-mr-72 {
    margin-right: var(--spacing-72);
}

.inf-mr-80 {
    margin-right: var(--spacing-80);
}

.inf-mr-96 {
    margin-right: var(--spacing-96);
}

.inf-mr-auto {
    margin-right: auto;
}

/* Margin-top classes based on spacing variables */
.inf-mt-0 {
    margin-top: var(--spacing-0);
}

.inf-mt-0p5 {
    margin-top: var(--spacing-0p5);
}

.inf-mt-1 {
    margin-top: var(--spacing-1);
}

.inf-mt-1p5 {
    margin-top: var(--spacing-1p5);
}

.inf-mt-2 {
    margin-top: var(--spacing-2);
}

.inf-mt-2p5 {
    margin-top: var(--spacing-2p5);
}

.inf-mt-3 {
    margin-top: var(--spacing-3);
}

.inf-mt-3p5 {
    margin-top: var(--spacing-3p5);
}

.inf-mt-4 {
    margin-top: var(--spacing-4);
}

.inf-mt-5 {
    margin-top: var(--spacing-5);
}

.inf-mt-6 {
    margin-top: var(--spacing-6);
}

.inf-mt-7 {
    margin-top: var(--spacing-7);
}

.inf-mt-8 {
    margin-top: var(--spacing-8);
}

.inf-mt-9 {
    margin-top: var(--spacing-9);
}

.inf-mt-10 {
    margin-top: var(--spacing-10);
}

.inf-mt-11 {
    margin-top: var(--spacing-11);
}

.inf-mt-12 {
    margin-top: var(--spacing-12);
}

.inf-mt-14 {
    margin-top: var(--spacing-14);
}

.inf-mt-16 {
    margin-top: var(--spacing-16);
}

.inf-mt-20 {
    margin-top: var(--spacing-20);
}

.inf-mt-24 {
    margin-top: var(--spacing-24);
}

.inf-mt-28 {
    margin-top: var(--spacing-28);
}

.inf-mt-32 {
    margin-top: var(--spacing-32);
}

.inf-mt-36 {
    margin-top: var(--spacing-36);
}

.inf-mt-40 {
    margin-top: var(--spacing-40);
}

.inf-mt-44 {
    margin-top: var(--spacing-44);
}

.inf-mt-48 {
    margin-top: var(--spacing-48);
}

.inf-mt-52 {
    margin-top: var(--spacing-52);
}

.inf-mt-56 {
    margin-top: var(--spacing-56);
}

.inf-mt-60 {
    margin-top: var(--spacing-60);
}

.inf-mt-64 {
    margin-top: var(--spacing-64);
}

.inf-mt-72 {
    margin-top: var(--spacing-72);
}

.inf-mt-80 {
    margin-top: var(--spacing-80);
}

.inf-mt-96 {
    margin-top: var(--spacing-96);
}

/* Margin-bottom classes based on spacing variables */
.inf-mb-0 {
    margin-bottom: var(--spacing-0);
}

.inf-mb-0p5 {
    margin-bottom: var(--spacing-0p5);
}

.inf-mb-1 {
    margin-bottom: var(--spacing-1);
}

.inf-mb-1p5 {
    margin-bottom: var(--spacing-1p5);
}

.inf-mb-2 {
    margin-bottom: var(--spacing-2);
}

.inf-mb-2p5 {
    margin-bottom: var(--spacing-2p5);
}

.inf-mb-3 {
    margin-bottom: var(--spacing-3);
}

.inf-mb-3p5 {
    margin-bottom: var(--spacing-3p5);
}

.inf-mb-4 {
    margin-bottom: var(--spacing-4);
}

.inf-mb-5 {
    margin-bottom: var(--spacing-5);
}

.inf-mb-6 {
    margin-bottom: var(--spacing-6);
}

.inf-mb-7 {
    margin-bottom: var(--spacing-7);
}

.inf-mb-8 {
    margin-bottom: var(--spacing-8);
}

.inf-mb-9 {
    margin-bottom: var(--spacing-9);
}

.inf-mb-10 {
    margin-bottom: var(--spacing-10);
}

.inf-mb-11 {
    margin-bottom: var(--spacing-11);
}

.inf-mb-12 {
    margin-bottom: var(--spacing-12);
}

.inf-mb-14 {
    margin-bottom: var(--spacing-14);
}

.inf-mb-16 {
    margin-bottom: var(--spacing-16);
}

.inf-mb-20 {
    margin-bottom: var(--spacing-20);
}

.inf-mb-24 {
    margin-bottom: var(--spacing-24);
}

.inf-mb-28 {
    margin-bottom: var(--spacing-28);
}

.inf-mb-32 {
    margin-bottom: var(--spacing-32);
}

.inf-mb-36 {
    margin-bottom: var(--spacing-36);
}

.inf-mb-40 {
    margin-bottom: var(--spacing-40);
}

.inf-mb-44 {
    margin-bottom: var(--spacing-44);
}

.inf-mb-48 {
    margin-bottom: var(--spacing-48);
}

.inf-mb-52 {
    margin-bottom: var(--spacing-52);
}

.inf-mb-56 {
    margin-bottom: var(--spacing-56);
}

.inf-mb-60 {
    margin-bottom: var(--spacing-60);
}

.inf-mb-64 {
    margin-bottom: var(--spacing-64);
}

.inf-mb-72 {
    margin-bottom: var(--spacing-72);
}

.inf-mb-80 {
    margin-bottom: var(--spacing-80);
}

.inf-mb-96 {
    margin-bottom: var(--spacing-96);
}

/* Horizontal Margin */
.inf-mx-0 {
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
}

.inf-mx-0p5 {
    margin-left: var(--spacing-0p5);
    margin-right: var(--spacing-0p5);
}

.inf-mx-1 {
    margin-left: var(--spacing-1);
    margin-right: var(--spacing-1);
}

.inf-mx-1p5 {
    margin-left: var(--spacing-1p5);
    margin-right: var(--spacing-1p5);
}

.inf-mx-2 {
    margin-left: var(--spacing-2);
    margin-right: var(--spacing-2);
}

.inf-mx-2p5 {
    margin-left: var(--spacing-2p5);
    margin-right: var(--spacing-2p5);
}

.inf-mx-3 {
    margin-left: var(--spacing-3);
    margin-right: var(--spacing-3);
}

.inf-mx-3p5 {
    margin-left: var(--spacing-3p5);
    margin-right: var(--spacing-3p5);
}

.inf-mx-4 {
    margin-left: var(--spacing-4);
    margin-right: var(--spacing-4);
}

.inf-mx-5 {
    margin-left: var(--spacing-5);
    margin-right: var(--spacing-5);
}

.inf-mx-6 {
    margin-left: var(--spacing-6);
    margin-right: var(--spacing-6);
}

.inf-mx-7 {
    margin-left: var(--spacing-7);
    margin-right: var(--spacing-7);
}

.inf-mx-8 {
    margin-left: var(--spacing-8);
    margin-right: var(--spacing-8);
}

.inf-mx-9 {
    margin-left: var(--spacing-9);
    margin-right: var(--spacing-9);
}

.inf-mx-10 {
    margin-left: var(--spacing-10);
    margin-right: var(--spacing-10);
}

.inf-mx-11 {
    margin-left: var(--spacing-11);
    margin-right: var(--spacing-11);
}

.inf-mx-12 {
    margin-left: var(--spacing-12);
    margin-right: var(--spacing-12);
}

.inf-mx-14 {
    margin-left: var(--spacing-14);
    margin-right: var(--spacing-14);
}

.inf-mx-16 {
    margin-left: var(--spacing-16);
    margin-right: var(--spacing-16);
}

.inf-mx-20 {
    margin-left: var(--spacing-20);
    margin-right: var(--spacing-20);
}

.inf-mx-24 {
    margin-left: var(--spacing-24);
    margin-right: var(--spacing-24);
}

.inf-mx-28 {
    margin-left: var(--spacing-28);
    margin-right: var(--spacing-28);
}

.inf-mx-32 {
    margin-left: var(--spacing-32);
    margin-right: var(--spacing-32);
}

.inf-mx-36 {
    margin-left: var(--spacing-36);
    margin-right: var(--spacing-36);
}

.inf-mx-40 {
    margin-left: var(--spacing-40);
    margin-right: var(--spacing-40);
}

.inf-mx-44 {
    margin-left: var(--spacing-44);
    margin-right: var(--spacing-44);
}

.inf-mx-48 {
    margin-left: var(--spacing-48);
    margin-right: var(--spacing-48);
}

.inf-mx-52 {
    margin-left: var(--spacing-52);
    margin-right: var(--spacing-52);
}

.inf-mx-56 {
    margin-left: var(--spacing-56);
    margin-right: var(--spacing-56);
}

.inf-mx-60 {
    margin-left: var(--spacing-60);
    margin-right: var(--spacing-60);
}

.inf-mx-64 {
    margin-left: var(--spacing-64);
    margin-right: var(--spacing-64);
}

.inf-mx-72 {
    margin-left: var(--spacing-72);
    margin-right: var(--spacing-72);
}

.inf-mx-80 {
    margin-left: var(--spacing-80);
    margin-right: var(--spacing-80);
}

.inf-mx-96 {
    margin-left: var(--spacing-96);
    margin-right: var(--spacing-96);
}

/* Vertical Margin */
.inf-my-0 {
    margin-top: var(--spacing-0);
    margin-bottom: var(--spacing-0);
}

.inf-my-0p5 {
    margin-top: var(--spacing-0p5);
    margin-bottom: var(--spacing-0p5);
}

.inf-my-1 {
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-1);
}

.inf-my-1p5 {
    margin-top: var(--spacing-1p5);
    margin-bottom: var(--spacing-1p5);
}

.inf-my-2 {
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
}

.inf-my-2p5 {
    margin-top: var(--spacing-2p5);
    margin-bottom: var(--spacing-2p5);
}

.inf-my-3 {
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-3);
}

.inf-my-3p5 {
    margin-top: var(--spacing-3p5);
    margin-bottom: var(--spacing-3p5);
}

.inf-my-4 {
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-4);
}

.inf-my-5 {
    margin-top: var(--spacing-5);
    margin-bottom: var(--spacing-5);
}

.inf-my-6 {
    margin-top: var(--spacing-6);
    margin-bottom: var(--spacing-6);
}

.inf-my-7 {
    margin-top: var(--spacing-7);
    margin-bottom: var(--spacing-7);
}

.inf-my-8 {
    margin-top: var(--spacing-8);
    margin-bottom: var(--spacing-8);
}

.inf-my-9 {
    margin-top: var(--spacing-9);
    margin-bottom: var(--spacing-9);
}

.inf-my-10 {
    margin-top: var(--spacing-10);
    margin-bottom: var(--spacing-10);
}

.inf-my-11 {
    margin-top: var(--spacing-11);
    margin-bottom: var(--spacing-11);
}

.inf-my-12 {
    margin-top: var(--spacing-12);
    margin-bottom: var(--spacing-12);
}

.inf-my-14 {
    margin-top: var(--spacing-14);
    margin-bottom: var(--spacing-14);
}

.inf-my-16 {
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-16);
}

.inf-my-20 {
    margin-top: var(--spacing-20);
    margin-bottom: var(--spacing-20);
}

.inf-my-24 {
    margin-top: var(--spacing-24);
    margin-bottom: var(--spacing-24);
}

.inf-my-28 {
    margin-top: var(--spacing-28);
    margin-bottom: var(--spacing-28);
}

.inf-my-32 {
    margin-top: var(--spacing-32);
    margin-bottom: var(--spacing-32);
}

.inf-my-36 {
    margin-top: var(--spacing-36);
    margin-bottom: var(--spacing-36);
}

.inf-my-40 {
    margin-top: var(--spacing-40);
    margin-bottom: var(--spacing-40);
}

.inf-my-44 {
    margin-top: var(--spacing-44);
    margin-bottom: var(--spacing-44);
}

.inf-my-48 {
    margin-top: var(--spacing-48);
    margin-bottom: var(--spacing-48);
}

.inf-my-52 {
    margin-top: var(--spacing-52);
    margin-bottom: var(--spacing-52);
}

.inf-my-56 {
    margin-top: var(--spacing-56);
    margin-bottom: var(--spacing-56);
}

.inf-my-60 {
    margin-top: var(--spacing-60);
    margin-bottom: var(--spacing-60);
}

.inf-my-64 {
    margin-top: var(--spacing-64);
    margin-bottom: var(--spacing-64);
}

.inf-my-72 {
    margin-top: var(--spacing-72);
    margin-bottom: var(--spacing-72);
}

.inf-my-80 {
    margin-top: var(--spacing-80);
    margin-bottom: var(--spacing-80);
}

.inf-my-96 {
    margin-top: var(--spacing-96);
    margin-bottom: var(--spacing-96);
}

/* Padding classes based on spacing variables */
.inf-p-0 {
    padding: var(--spacing-0);
}

.inf-p-0p5 {
    padding: var(--spacing-0p5);
}

.inf-p-1 {
    padding: var(--spacing-1);
}

.inf-p-1p5 {
    padding: var(--spacing-1p5);
}

.inf-p-2 {
    padding: var(--spacing-2);
}

.inf-p-2p5 {
    padding: var(--spacing-2p5);
}

.inf-p-3 {
    padding: var(--spacing-3);
}

.inf-p-3p5 {
    padding: var(--spacing-3p5);
}

.inf-p-4 {
    padding: var(--spacing-4);
}

.inf-p-5 {
    padding: var(--spacing-5);
}

.inf-p-6 {
    padding: var(--spacing-6);
}

.inf-p-7 {
    padding: var(--spacing-7);
}

.inf-p-8 {
    padding: var(--spacing-8);
}

.inf-p-9 {
    padding: var(--spacing-9);
}

.inf-p-10 {
    padding: var(--spacing-10);
}

.inf-p-11 {
    padding: var(--spacing-11);
}

.inf-p-12 {
    padding: var(--spacing-12);
}

.inf-p-14 {
    padding: var(--spacing-14);
}

.inf-p-16 {
    padding: var(--spacing-16);
}

.inf-p-20 {
    padding: var(--spacing-20);
}

.inf-p-24 {
    padding: var(--spacing-24);
}

.inf-p-28 {
    padding: var(--spacing-28);
}

.inf-p-32 {
    padding: var(--spacing-32);
}

.inf-p-36 {
    padding: var(--spacing-36);
}

.inf-p-40 {
    padding: var(--spacing-40);
}

.inf-p-44 {
    padding: var(--spacing-44);
}

.inf-p-48 {
    padding: var(--spacing-48);
}

.inf-p-52 {
    padding: var(--spacing-52);
}

.inf-p-56 {
    padding: var(--spacing-56);
}

.inf-p-60 {
    padding: var(--spacing-60);
}

.inf-p-64 {
    padding: var(--spacing-64);
}

.inf-p-72 {
    padding: var(--spacing-72);
}

.inf-p-80 {
    padding: var(--spacing-80);
}

.inf-p-96 {
    padding: var(--spacing-96);
}


/* Padding-left classes based on spacing variables */
.inf-pl-0 {
    padding-left: var(--spacing-0);
}

.inf-pl-0p5 {
    padding-left: var(--spacing-0p5);
}

.inf-pl-1 {
    padding-left: var(--spacing-1);
}

.inf-pl-1p5 {
    padding-left: var(--spacing-1p5);
}

.inf-pl-2 {
    padding-left: var(--spacing-2);
}

.inf-pl-2p5 {
    padding-left: var(--spacing-2p5);
}

.inf-pl-3 {
    padding-left: var(--spacing-3);
}

.inf-pl-3p5 {
    padding-left: var(--spacing-3p5);
}

.inf-pl-4 {
    padding-left: var(--spacing-4);
}

.inf-pl-5 {
    padding-left: var(--spacing-5);
}

.inf-pl-6 {
    padding-left: var(--spacing-6);
}

.inf-pl-7 {
    padding-left: var(--spacing-7);
}

.inf-pl-8 {
    padding-left: var(--spacing-8);
}

.inf-pl-9 {
    padding-left: var(--spacing-9);
}

.inf-pl-10 {
    padding-left: var(--spacing-10);
}

.inf-pl-11 {
    padding-left: var(--spacing-11);
}

.inf-pl-12 {
    padding-left: var(--spacing-12);
}

.inf-pl-14 {
    padding-left: var(--spacing-14);
}

.inf-pl-16 {
    padding-left: var(--spacing-16);
}

.inf-pl-20 {
    padding-left: var(--spacing-20);
}

.inf-pl-24 {
    padding-left: var(--spacing-24);
}

.inf-pl-28 {
    padding-left: var(--spacing-28);
}

.inf-pl-32 {
    padding-left: var(--spacing-32);
}

.inf-pl-36 {
    padding-left: var(--spacing-36);
}

.inf-pl-40 {
    padding-left: var(--spacing-40);
}

.inf-pl-44 {
    padding-left: var(--spacing-44);
}

.inf-pl-48 {
    padding-left: var(--spacing-48);
}

.inf-pl-52 {
    padding-left: var(--spacing-52);
}

.inf-pl-56 {
    padding-left: var(--spacing-56);
}

.inf-pl-60 {
    padding-left: var(--spacing-60);
}

.inf-pl-64 {
    padding-left: var(--spacing-64);
}

.inf-pl-72 {
    padding-left: var(--spacing-72);
}

.inf-pl-80 {
    padding-left: var(--spacing-80);
}

.inf-pl-96 {
    padding-left: var(--spacing-96);
}

/* Padding-right classes based on spacing variables */
.inf-pr-0 {
    padding-right: var(--spacing-0);
}

.inf-pr-0p5 {
    padding-right: var(--spacing-0p5);
}

.inf-pr-1 {
    padding-right: var(--spacing-1);
}

.inf-pr-1p5 {
    padding-right: var(--spacing-1p5);
}

.inf-pr-2 {
    padding-right: var(--spacing-2);
}

.inf-pr-2p5 {
    padding-right: var(--spacing-2p5);
}

.inf-pr-3 {
    padding-right: var(--spacing-3);
}

.inf-pr-3p5 {
    padding-right: var(--spacing-3p5);
}

.inf-pr-4 {
    padding-right: var(--spacing-4);
}

.inf-pr-5 {
    padding-right: var(--spacing-5);
}

.inf-pr-6 {
    padding-right: var(--spacing-6);
}

.inf-pr-7 {
    padding-right: var(--spacing-7);
}

.inf-pr-8 {
    padding-right: var(--spacing-8);
}

.inf-pr-9 {
    padding-right: var(--spacing-9);
}

.inf-pr-10 {
    padding-right: var(--spacing-10);
}

.inf-pr-11 {
    padding-right: var(--spacing-11);
}

.inf-pr-12 {
    padding-right: var(--spacing-12);
}

.inf-pr-14 {
    padding-right: var(--spacing-14);
}

.inf-pr-16 {
    padding-right: var(--spacing-16);
}

.inf-pr-20 {
    padding-right: var(--spacing-20);
}

.inf-pr-24 {
    padding-right: var(--spacing-24);
}

.inf-pr-28 {
    padding-right: var(--spacing-28);
}

.inf-pr-32 {
    padding-right: var(--spacing-32);
}

.inf-pr-36 {
    padding-right: var(--spacing-36);
}

.inf-pr-40 {
    padding-right: var(--spacing-40);
}

.inf-pr-44 {
    padding-right: var(--spacing-44);
}

.inf-pr-48 {
    padding-right: var(--spacing-48);
}

.inf-pr-52 {
    padding-right: var(--spacing-52);
}

.inf-pr-56 {
    padding-right: var(--spacing-56);
}

.inf-pr-60 {
    padding-right: var(--spacing-60);
}

.inf-pr-64 {
    padding-right: var(--spacing-64);
}

.inf-pr-72 {
    padding-right: var(--spacing-72);
}

.inf-pr-80 {
    padding-right: var(--spacing-80);
}

.inf-pr-96 {
    padding-right: var(--spacing-96);
}

/* Padding-top classes based on spacing variables */
.inf-pt-0 {
    padding-top: var(--spacing-0);
}

.inf-pt-0p5 {
    padding-top: var(--spacing-0p5);
}

.inf-pt-1 {
    padding-top: var(--spacing-1);
}

.inf-pt-1p5 {
    padding-top: var(--spacing-1p5);
}

.inf-pt-2 {
    padding-top: var(--spacing-2);
}

.inf-pt-2p5 {
    padding-top: var(--spacing-2p5);
}

.inf-pt-3 {
    padding-top: var(--spacing-3);
}

.inf-pt-3p5 {
    padding-top: var(--spacing-3p5);
}

.inf-pt-4 {
    padding-top: var(--spacing-4);
}

.inf-pt-5 {
    padding-top: var(--spacing-5);
}

.inf-pt-6 {
    padding-top: var(--spacing-6);
}

.inf-pt-7 {
    padding-top: var(--spacing-7);
}

.inf-pt-8 {
    padding-top: var(--spacing-8);
}

.inf-pt-9 {
    padding-top: var(--spacing-9);
}

.inf-pt-10 {
    padding-top: var(--spacing-10);
}

.inf-pt-11 {
    padding-top: var(--spacing-11);
}

.inf-pt-12 {
    padding-top: var(--spacing-12);
}

.inf-pt-14 {
    padding-top: var(--spacing-14);
}

.inf-pt-16 {
    padding-top: var(--spacing-16);
}

.inf-pt-20 {
    padding-top: var(--spacing-20);
}

.inf-pt-24 {
    padding-top: var(--spacing-24);
}

.inf-pt-28 {
    padding-top: var(--spacing-28);
}

.inf-pt-32 {
    padding-top: var(--spacing-32);
}

.inf-pt-36 {
    padding-top: var(--spacing-36);
}

.inf-pt-40 {
    padding-top: var(--spacing-40);
}

.inf-pt-44 {
    padding-top: var(--spacing-44);
}

.inf-pt-48 {
    padding-top: var(--spacing-48);
}

.inf-pt-52 {
    padding-top: var(--spacing-52);
}

.inf-pt-56 {
    padding-top: var(--spacing-56);
}

.inf-pt-60 {
    padding-top: var(--spacing-60);
}

.inf-pt-64 {
    padding-top: var(--spacing-64);
}

.inf-pt-72 {
    padding-top: var(--spacing-72);
}

.inf-pt-80 {
    padding-top: var(--spacing-80);
}

.inf-pt-96 {
    padding-top: var(--spacing-96);
}

/* Padding-bottom classes based on spacing variables */
.inf-pb-0 {
    padding-bottom: var(--spacing-0);
}

.inf-pb-0p5 {
    padding-bottom: var(--spacing-0p5);
}

.inf-pb-1 {
    padding-bottom: var(--spacing-1);
}

.inf-pb-1p5 {
    padding-bottom: var(--spacing-1p5);
}

.inf-pb-2 {
    padding-bottom: var(--spacing-2);
}

.inf-pb-2p5 {
    padding-bottom: var(--spacing-2p5);
}

.inf-pb-3 {
    padding-bottom: var(--spacing-3);
}

.inf-pb-3p5 {
    padding-bottom: var(--spacing-3p5);
}

.inf-pb-4 {
    padding-bottom: var(--spacing-4);
}

.inf-pb-5 {
    padding-bottom: var(--spacing-5);
}

.inf-pb-6 {
    padding-bottom: var(--spacing-6);
}

.inf-pb-7 {
    padding-bottom: var(--spacing-7);
}

.inf-pb-8 {
    padding-bottom: var(--spacing-8);
}

.inf-pb-9 {
    padding-bottom: var(--spacing-9);
}

.inf-pb-10 {
    padding-bottom: var(--spacing-10);
}

.inf-pb-11 {
    padding-bottom: var(--spacing-11);
}

.inf-pb-12 {
    padding-bottom: var(--spacing-12);
}

.inf-pb-14 {
    padding-bottom: var(--spacing-14);
}

.inf-pb-16 {
    padding-bottom: var(--spacing-16);
}

.inf-pb-20 {
    padding-bottom: var(--spacing-20);
}

.inf-pb-24 {
    padding-bottom: var(--spacing-24);
}

.inf-pb-28 {
    padding-bottom: var(--spacing-28);
}

.inf-pb-32 {
    padding-bottom: var(--spacing-32);
}

.inf-pb-36 {
    padding-bottom: var(--spacing-36);
}

.inf-pb-40 {
    padding-bottom: var(--spacing-40);
}

.inf-pb-44 {
    padding-bottom: var(--spacing-44);
}

.inf-pb-48 {
    padding-bottom: var(--spacing-48);
}

.inf-pb-52 {
    padding-bottom: var(--spacing-52);
}

.inf-pb-56 {
    padding-bottom: var(--spacing-56);
}

.inf-pb-60 {
    padding-bottom: var(--spacing-60);
}

.inf-pb-64 {
    padding-bottom: var(--spacing-64);
}

.inf-pb-72 {
    padding-bottom: var(--spacing-72);
}

.inf-pb-80 {
    padding-bottom: var(--spacing-80);
}

.inf-pb-96 {
    padding-bottom: var(--spacing-96);
}

/* Horizontal Padding */
.inf-px-0 {
    padding-left: var(--spacing-0);
    padding-right: var(--spacing-0);
}

.inf-px-0p5 {
    padding-left: var(--spacing-0p5);
    padding-right: var(--spacing-0p5);
}

.inf-px-1 {
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
}

.inf-px-1p5 {
    padding-left: var(--spacing-1p5);
    padding-right: var(--spacing-1p5);
}

.inf-px-2 {
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
}

.inf-px-2p5 {
    padding-left: var(--spacing-2p5);
    padding-right: var(--spacing-2p5);
}

.inf-px-3 {
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
}

.inf-px-3p5 {
    padding-left: var(--spacing-3p5);
    padding-right: var(--spacing-3p5);
}

.inf-px-4 {
    padding-left: var(--spacing-4);
    padding-right: var(--spacing-4);
}

.inf-px-5 {
    padding-left: var(--spacing-5);
    padding-right: var(--spacing-5);
}

.inf-px-6 {
    padding-left: var(--spacing-6);
    padding-right: var(--spacing-6);
}

.inf-px-7 {
    padding-left: var(--spacing-7);
    padding-right: var(--spacing-7);
}

.inf-px-8 {
    padding-left: var(--spacing-8);
    padding-right: var(--spacing-8);
}

.inf-px-9 {
    padding-left: var(--spacing-9);
    padding-right: var(--spacing-9);
}

.inf-px-10 {
    padding-left: var(--spacing-10);
    padding-right: var(--spacing-10);
}

.inf-px-11 {
    padding-left: var(--spacing-11);
    padding-right: var(--spacing-11);
}

.inf-px-12 {
    padding-left: var(--spacing-12);
    padding-right: var(--spacing-12);
}

.inf-px-14 {
    padding-left: var(--spacing-14);
    padding-right: var(--spacing-14);
}

.inf-px-16 {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
}

.inf-px-20 {
    padding-left: var(--spacing-20);
    padding-right: var(--spacing-20);
}

.inf-px-24 {
    padding-left: var(--spacing-24);
    padding-right: var(--spacing-24);
}

.inf-px-28 {
    padding-left: var(--spacing-28);
    padding-right: var(--spacing-28);
}

.inf-px-32 {
    padding-left: var(--spacing-32);
    padding-right: var(--spacing-32);
}

.inf-px-36 {
    padding-left: var(--spacing-36);
    padding-right: var(--spacing-36);
}

.inf-px-40 {
    padding-left: var(--spacing-40);
    padding-right: var(--spacing-40);
}

.inf-px-44 {
    padding-left: var(--spacing-44);
    padding-right: var(--spacing-44);
}

.inf-px-48 {
    padding-left: var(--spacing-48);
    padding-right: var(--spacing-48);
}

.inf-px-52 {
    padding-left: var(--spacing-52);
    padding-right: var(--spacing-52);
}

.inf-px-56 {
    padding-left: var(--spacing-56);
    padding-right: var(--spacing-56);
}

.inf-px-60 {
    padding-left: var(--spacing-60);
    padding-right: var(--spacing-60);
}

.inf-px-64 {
    padding-left: var(--spacing-64);
    padding-right: var(--spacing-64);
}

.inf-px-72 {
    padding-left: var(--spacing-72);
    padding-right: var(--spacing-72);
}

.inf-px-80 {
    padding-left: var(--spacing-80);
    padding-right: var(--spacing-80);
}

.inf-px-96 {
    padding-left: var(--spacing-96);
    padding-right: var(--spacing-96);
}

/* Vertical Padding */
.inf-py-0 {
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
}

.inf-py-0p5 {
    padding-top: var(--spacing-0p5);
    padding-bottom: var(--spacing-0p5);
}

.inf-py-1 {
    padding-top: var(--spacing-1);
    padding-bottom: var(--spacing-1);
}

.inf-py-1p5 {
    padding-top: var(--spacing-1p5);
    padding-bottom: var(--spacing-1p5);
}

.inf-py-2 {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
}

.inf-py-2p5 {
    padding-top: var(--spacing-2p5);
    padding-bottom: var(--spacing-2p5);
}

.inf-py-3 {
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
}

.inf-py-3p5 {
    padding-top: var(--spacing-3p5);
    padding-bottom: var(--spacing-3p5);
}

.inf-py-4 {
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-4);
}

.inf-py-5 {
    padding-top: var(--spacing-5);
    padding-bottom: var(--spacing-5);
}

.inf-py-6 {
    padding-top: var(--spacing-6);
    padding-bottom: var(--spacing-6);
}

.inf-py-7 {
    padding-top: var(--spacing-7);
    padding-bottom: var(--spacing-7);
}

.inf-py-8 {
    padding-top: var(--spacing-8);
    padding-bottom: var(--spacing-8);
}

.inf-py-9 {
    padding-top: var(--spacing-9);
    padding-bottom: var(--spacing-9);
}

.inf-py-10 {
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-10);
}

.inf-py-11 {
    padding-top: var(--spacing-11);
    padding-bottom: var(--spacing-11);
}

.inf-py-12 {
    padding-top: var(--spacing-12);
    padding-bottom: var(--spacing-12);
}

.inf-py-14 {
    padding-top: var(--spacing-14);
    padding-bottom: var(--spacing-14);
}

.inf-py-16 {
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
}

.inf-py-20 {
    padding-top: var(--spacing-20);
    padding-bottom: var(--spacing-20);
}

.inf-py-24 {
    padding-top: var(--spacing-24);
    padding-bottom: var(--spacing-24);
}

.inf-py-28 {
    padding-top: var(--spacing-28);
    padding-bottom: var(--spacing-28);
}

.inf-py-32 {
    padding-top: var(--spacing-32);
    padding-bottom: var(--spacing-32);
}

.inf-py-36 {
    padding-top: var(--spacing-36);
    padding-bottom: var(--spacing-36);
}

.inf-py-40 {
    padding-top: var(--spacing-40);
    padding-bottom: var(--spacing-40);
}

.inf-py-44 {
    padding-top: var(--spacing-44);
    padding-bottom: var(--spacing-44);
}

.inf-py-48 {
    padding-top: var(--spacing-48);
    padding-bottom: var(--spacing-48);
}

.inf-py-52 {
    padding-top: var(--spacing-52);
    padding-bottom: var(--spacing-52);
}

.inf-py-56 {
    padding-top: var(--spacing-56);
    padding-bottom: var(--spacing-56);
}

.inf-py-60 {
    padding-top: var(--spacing-60);
    padding-bottom: var(--spacing-60);
}

.inf-py-64 {
    padding-top: var(--spacing-64);
    padding-bottom: var(--spacing-64);
}

.inf-py-72 {
    padding-top: var(--spacing-72);
    padding-bottom: var(--spacing-72);
}

.inf-py-80 {
    padding-top: var(--spacing-80);
    padding-bottom: var(--spacing-80);
}

.inf-py-96 {
    padding-top: var(--spacing-96);
    padding-bottom: var(--spacing-96);
}

/* Font Sizes */
.inf-text-xs {
    font-size: 0.75rem;
    /* 12px */
    line-height: 1rem;
    /* 16px */
}

.inf-text-sm {
    font-size: 0.875rem;
    /* 14px */
    line-height: 1.25rem;
    /* 20px */
}

.inf-text-base {
    font-size: 1rem;
    /* 16px */
    line-height: 1.5rem;
    /* 24px */
}

.inf-text-lg {
    font-size: 1.125rem;
    /* 18px */
    line-height: 1.75rem;
    /* 28px */
}

.inf-text-xl {
    font-size: 1.25rem;
    /* 20px */
    line-height: 1.75rem;
    /* 28px */
}

.inf-text-2xl {
    font-size: 1.5rem;
    /* 24px */
    line-height: 2rem;
    /* 32px */
}

.inf-text-3xl {
    font-size: 1.875rem;
    /* 30px */
    line-height: 2.25rem;
    /* 36px */
}

.inf-text-4xl {
    font-size: 2.25rem;
    /* 36px */
    line-height: 2.5rem;
    /* 40px */
}

.inf-text-5xl {
    font-size: 3rem;
    /* 48px */
    line-height: 1;
}

.inf-text-6xl {
    font-size: 3.75rem;
    /* 60px */
    line-height: 1;
}

.inf-text-7xl {
    font-size: 4.5rem;
    /* 72px */
    line-height: 1;
}

.inf-text-8xl {
    font-size: 6rem;
    /* 96px */
    line-height: 1;
}

.inf-text-9xl {
    font-size: 8rem;
    /* 128px */
    line-height: 1;
}

/* Font Weights */
.inf-font-thin {
    font-weight: 100;
}

.inf-font-extralight {
    font-weight: 200;
}

.inf-font-light {
    font-weight: 300;
}

.inf-font-normal {
    font-weight: 400;
}

.inf-font-medium {
    font-weight: 500;
}

.inf-font-semibold {
    font-weight: 600;
}

.inf-font-bold {
    font-weight: 700;
}

.inf-font-extrabold {
    font-weight: 800;
}

.inf-font-thick {
    font-weight: 900;
}

/* Flex Classes */

.inf-flex {
    display: flex;
}

.inf-flex-col {
    flex-direction: column;
}

.inf-flex-wrap {
    flex-wrap: wrap;
}

.inf-justify-end {
    justify-content: end;
}

.inf-justify-center {
    justify-content: center;
}

.inf-justify-between {
    justify-content: space-between;
}

.inf-items-center {
    align-items: center;
}

.inf-gap-0 {
    --inf-flex-grid-gap: 0rem;
    grid-gap: var(--inf-flex-grid-gap, 0rem);
    gap: var(--inf-flex-grid-gap, 0rem)
}

.inf-gap-1 {
    --inf-flex-grid-gap: 0.25rem;
    grid-gap: var(--inf-flex-grid-gap, 0.25rem);
    gap: var(--inf-flex-grid-gap, 0.25rem)
}

.inf-gap-2 {
    --inf-flex-grid-gap: 0.5rem;
    grid-gap: var(--inf-flex-grid-gap, 0.5rem);
    gap: var(--inf-flex-grid-gap, 0.5rem)
}

.inf-gap-3 {
    --inf-flex-grid-gap: 0.75rem;
    grid-gap: var(--inf-flex-grid-gap, 0.75rem);
    gap: var(--inf-flex-grid-gap, 0.75rem)
}

.inf-gap-4 {
    --inf-flex-grid-gap: 1rem;
    grid-gap: var(--inf-flex-grid-gap, 1rem);
    gap: var(--inf-flex-grid-gap, 1rem)
}

.inf-gap-5 {
    --inf-flex-grid-gap: 1.25rem;
    grid-gap: var(--inf-flex-grid-gap, 1.25rem);
    gap: var(--inf-flex-grid-gap, 1.25rem)
}

.inf-gap-6 {
    --inf-flex-grid-gap: 1.5rem;
    grid-gap: var(--inf-flex-grid-gap, 1.5rem);
    gap: var(--inf-flex-grid-gap, 1.5rem)
}

.inf-gap-7 {
    --inf-flex-grid-gap: 1.75rem;
    grid-gap: var(--inf-flex-grid-gap, 1.75rem);
    gap: var(--inf-flex-grid-gap, 1.75rem)
}

.inf-gap-8 {
    --inf-flex-grid-gap: 2rem;
    grid-gap: var(--inf-flex-grid-gap, 2rem);
    gap: var(--inf-flex-grid-gap, 2rem)
}

.inf-gap-9 {
    --inf-flex-grid-gap: 2.25rem;
    grid-gap: var(--inf-flex-grid-gap, 2.25rem);
    gap: var(--inf-flex-grid-gap, 2.25rem)
}

.inf-gap-10 {
    --inf-flex-grid-gap: 2.5rem;
    grid-gap: var(--inf-flex-grid-gap, 2.5rem);
    gap: var(--inf-flex-grid-gap, 2.5rem)
}

.inf-gap-11 {
    --inf-flex-grid-gap: 2.75rem;
    grid-gap: var(--inf-flex-grid-gap, 2.75rem);
    gap: var(--inf-flex-grid-gap, 2.75rem)
}

.inf-gap-12 {
    --inf-flex-grid-gap: 3rem;
    grid-gap: var(--inf-flex-grid-gap, 3rem);
    gap: var(--inf-flex-grid-gap, 3rem)
}

/* Text Alignment */
.inf-text-left {
    text-align: left;
}

.inf-text-center {
    text-align: center;
}

.inf-text-right {
    text-align: right;
}

.inf-text-justify {
    text-align: justify;
}

.inf-text-start {
    text-align: start;
}

.inf-text-end {
    text-align: end;
}

/* Bounding box */
.inf-w-full {
    width: 100%;
}

.inf-h-full {
    height: 100%;
}

/* Border Radius */
.rounded	{
    border-radius: 0.25rem;
}

.rounded-md	{
    border-radius: 0.375rem;
}

.rounded-lg	{
    border-radius: 0.5rem;
}

.rounded-xl    {
    border-radius: 0.75rem;
}

.rounded-2xl    {
    border-radius: 1rem;
}

.rounded-full	{
    border-radius: 9999px;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-auto {
    overflow: auto;
}
:root .form-control {
    font-size: 1rem;
}

.ant-tooltip-inner {
    max-height: 300px;
    overflow: auto;
}

.ant-dropdown-menu-sub {
    max-height: 300px;
    overflow: auto;
}

.ant-dropdown-menu-submenu-title{
    display: flex !important;
    align-items: center;
}

.ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background: transparent !important;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
    background: #0078FF1A !important;
    border: 1px solid #0078FF80 !important;
}

.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #0078FF !important;
    font-weight: 700;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
    background: #E6E9EE !important;
    border: 1px solid #C5C7CF !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #676878 !important;
    font-weight: 700;
}


.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
    background: none !important;
    border: 1px solid #29C940 !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
    color: #29C940 !important;
    font-weight: 700;
}


/* .ant-dropdown-menu-item-group{
    margin: -4px -4px 0 -4px;
    background: #778394;
    border-radius: 4px 4px 0 0;
} */
.panel-mcd-color-picker .chrome-picker{
    width: 100% !important;
    box-shadow: unset !important;
}
.react-confirm-alert-overlay{
    background-color: rgba(0, 0, 0, 0.5) !important;
}

span:has(.react-joyride__beacon){
    top: -5px !important;
    left: 2px !important;
}

.react-joyride__overlay{
    height: 100vh !important;
}

.connector_set_selection_card .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner{
    border: 2px solid #C5C7CF;
}
.btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0,0,0,0.2);
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-block {
    display: block;
    width: 100%;
}

.btn_login{
    font-size: 14px !important;
    height: 34px;
    display: flex;
    align-items: center;
    width:100%;
}

.input_login{
    height:35px !important;
    font-size: 14px !important;
}

.btn_google:hover {
    color: #fff !important;
    background-color: #c23321 !important;
    border-color: rgba(0,0,0,0.2) !important;
}
.btn_linkedin:hover {
    color: #fff !important;
    background-color: #005983 !important;
    border-color: rgba(0,0,0,0.2) !important;
}

.forgot_password_hover:hover{
    color: #1634dd !important;
}
/* , .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success  */
.btn_success:hover{
    background-color: #1ABB9C !important;
    border-color: #1ABB9C i !important;
    color: #FFFFFF i !important;
}
.btn_default:hover {
    color: #333 i !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad i !important;
}
.login_icons{
    width: 32px;
    line-height: 34px;
    text-align: center;
    border-right: 1px solid rgba(0,0,0,0.2);
}

.sign_up_text_color{
    color:#73879C ;
    font-size:13px;
    font-weight: bold;
}

/* store options style */
.store_options_card{
    width: 350px;
    height: auto;
    position: absolute;
    left: calc((100% - 350px) / 2);
    top: 5%;
}

.all_store_options_div{
    overflow: auto;
    height:430px;
    padding:20px;
}

.single_store{
    height:50px;
    padding:0px 20px;
    margin-bottom:5px;
    background-color:#f9f9f9;
    font-size:14px;
    display:flex;
    align-items:center;
}

.single_store:hover{
    background-color:#f2f2f2 !important;
}

.single_store_text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:100%;
    text-transform: capitalize;
}
.single_store_icon{
    font-size: 20px;
    margin-right: 10px;
    color:#404040;
}
.disabled_dropdown_item {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

.scale_105 {
    transition: 0.3s ease-in;
}

.scale_105:hover {
    transform: scale(1.05);
}

.scale_110 {
    transition: 0.3s ease-in;
}

.scale_110:hover {
    transform: scale(1.1);
}

.invisiblescroll::-webkit-scrollbar {
    height: 0px
}
.infurnia_modal_header{
    height:48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E9EE;
    background-color:#FFFFFF;
    color: #202126;
    font-weight: 700;
    box-shadow: 0px 1px 6px 0px #0000000D;
    border-radius: 4px 4px 0px 0px;
    font-family: 'DM Sans', sans-serif;
    padding: 0px 16px;
    font-size: 16px;
  }
  .infurnia_modal_footer{
    /* height:52px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:#FFFFFF;
    padding: 8px 16px;
    border-top: 1px solid #E6E9EE;
    box-shadow: 0px -1px 6px 0px #0000000D;
    border-radius: 0px 0px 4px 4px;
    font-family: 'DM Sans', sans-serif;
  }
  .infurnia_modal_body{
    --inf-modal-body-background: #F5F6F8;
    /* height: 460px; */
    /* overflow: auto; */
    --inf-modal-body-padding: 16px;
    padding: var(--inf-modal-body-padding, 16px);
    background-color: var(--inf-modal-body-background, #F5F6F8);
    min-height: 100px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
  }

  .infurnia_modal_footer > :not(:last-child) {
    margin-right: 12px;
  }
  
.addon_card {
    border-radius: 6.769px;
    border: 1px solid #A7A8B2;
    background: #FAFBFB;
    display: flex;
    flex-direction: column;
}

.addon_card.addon_subscibed{
    border: 1px solid #23C16B
}

.addon_name {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 16px 0px;
}

.addon_pricing {
    background: #4597F7;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addon_subscibed .addon_pricing{
    background: #429C6C;
}

.addon_features {
    border-radius: 6.769px;
    background: var(--System-colors-System-Colors-Grey-4, #C5C7CF48);
    flex: 1 1;
    margin: 1rem;
    padding-right: 1.5rem;
}

.inf_share_user_modal_infurnia_user_badge{
    font-size: 10px;
    padding: 2px 4px;
    background-color: #3A63D420;
    color: #3A63D4;
    border-radius: 2px;
    /* color: #3A63D4; */
}
.infurnia_search_component_container_ideal {
    display:flex; 
    align-items:center; 
    border:1px solid #C5C7CF;
    border-radius: 2px;
    margin: 0px 16px 0px 16px;
    background-color: white;
}

.infurnia_search_component_inner_container {
    /* width:200px;  */
    flex: 1 1;
    min-width: 0;
    margin-right:16px; 
    font-size:12px;
    padding:4px 12px 4px 12px;
    border:0px;
}

.infurnia_search_component_container_ideal:focus-within {
    box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
    border: 1px solid #0078FF;
}

.infurnia_search_component_inner_container:focus-visible{
    outline: none;
}
.inf_3d_model_upload_header{
    padding: 8px 16px;
    border-bottom: 1px solid var(--light-border-color);
    background-color: white;
}

.inf_3d_model_upload_body{
    padding: 16px;
    background-color: white;
}
.search_component_container_ideal {
    display:flex; 
    align-items:center; 
    border:1px solid #C5C7CF;
    border-radius: 2px;
    margin: 0px 16px 0px 16px;
    background-color: white;
}

.search_component_inner_container {
    width:200px; 
    margin-right:16px; 
    font-size:12px;
    padding:6px 12px 6px 12px;
    border:0px;
    height: 100%;
}

.search_component_container_ideal:focus-within {
    box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
    border: 1px solid #0078FF;
}

.search_component_inner_container:focus-visible{
    outline: none;
}
.disabled_fa_icon {
    cursor: not-allowed  !important;
    opacity: 0.5 !important;
}

.vl {
    /* border-left: 6px solid green; */
    border-left: 2px solid #E6E9EE;;
    height: 36px;
    position: absolute;
    /* left: 50%; */
    /* margin-left: -3px; */
    top: 6px;
    left: 50px
}

/* .active_expand_160:active{
    width: 160px;
} */

.vl_end {
    /* border-left: 6px solid green; */
    border-left: 2px solid rgba(0, 0, 0, 0.15);
    height: 36px;
    /* position: absolute;
    /* left: 50%; */
    /* margin-left: -3px;
    top: 6px;
    left: 50px */
    margin-right: 16px;
}

.big_modal {
    width: 80vw;
}

#template_search:focus {
    box-shadow: none;
    /* border-radius: 2px; */
}

.edit_project_modal_div {
    display: flex;
    padding: 0px 32px;
    margin-bottom: 15px;
}

.search_container_ideal {
    display:flex; 
    align-items:center; 
    border:1px solid #C5C7CF;
    border-radius: 2px;
    background-color: white;
}

.search_container_ideal:focus-within {
    box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
    border: 1px solid #0078FF;
}

.search_container_ideal:hover {
    opacity: 0.9;
}

#tooltip
{
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 13px;
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 1s ease-in-out;
}

.show_on_hover:hover #tooltip
{
  margin-top: 50px;
  display: inline-block;
  opacity: 1;
  height: auto;
  transition-delay: 5s;
  background: transparent;
  z-index: 1;
  padding: 6px 12px 6px 12px;
  margin-left: 25px;
  border-radius: 6px;
  font-size: 14px;
  min-width: 330px;
  transition: opacity .5s ease-in-out;

  /* border: 1px solid white;
  border-radius: 2px; */
  /* border: 1px solid rgba(184, 191, 202, 1); */
}

#tooltip.bottom .tooltip-arrow
{
  top: 1px;
  left: 15%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: black;
}


/* .tooltip-arrow
{
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
} */

#tooltip .tooltip-arrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-label
{
  max-width: 500px;
  height: 40px;
  min-width:150px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.new_project_modal_div {
    display: flex;
    /* padding: 0px 32px; */
    margin-bottom: 16px;
}

.close{
    opacity: 1;
    /* color:#fff; */
}

.close:hover{
    opacity: 1;
    /* color:#fff; */
}

.help_menu{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-bottom: 32px;
}

@media only screen  and (max-width: 991px){
    .help_menu{
        width:100%;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content: space-between;
        padding: 12px;
    }
}

#project_table {
    border-collapse: collapse;
}

#project_table tr {
    border-right: 1px solid #E6E9EE;
    border-left: 1px solid #E6E9EE;
    padding: 6px 12px;
}

#project_table td {
    /* border: 1px solid #E6E9EE; */
    padding: 6px 12px;
}

#project_table th {
    /* border: 1px solid #E6E9EE; */
    padding: 6px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgba(167, 168, 178, 1) ;
    font-weight: 500;
}


#project_table tr:nth-child(odd){background-color: white;}
#project_table tr:nth-child(even){background-color: #F5F6F8;}
/* #project_table tr:hover {  background-color:rgb(214, 230, 240);} */

.infurnia-org-logo {
    max-width: 120px;
    max-height: calc(100% - 1rem);
    width: 100%;
    object-fit: contain
}

.infurnia-navbar {
    display: flex;
    height: var(--infurnia-navbar-height);
    width: 100%;
    /* Light Mode Properties */
    /* box-shadow: 0px 1px 1px rgba(0 0, 0, 0.25); */
    border-bottom: 1px solid rgba(0 0, 0, 0.25);
    background: var(--layer-main-bg);
    color: var(--layer-main-text-color);
    padding: 0rem 1.5rem;
}

.infurnia-sidebar{
    width: var(--infurnia-sidebar-width);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
    background: var(--layer-main-bg);
    text-align: left;
    transition: width 0.3s ease-in-out;
}

/* @media screen and (max-width: 641px) {
    :root{
        --infurnia-sidebar-width: 0px;
    }
    .infurnia-sidebar{
        transform: translateX(-100%);
        overflow: hidden;
    }
} */

.inf-sidebar-actions{
    flex: 1 1;
    padding: 1.5rem 1.5rem;
}

#new_project_button{
    background-color: #0078ff;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 12px;
}

.inf-sidebar-options-container{
    padding: 0.375rem;
    border-radius: 0.375rem;
    border: 1px solid var(--light-border-color);
}

.inf-sidebar-option{
    height: 2rem;
    border-radius: 0.25rem;
    padding: 0px 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    font-size: 12px;
}

.inf-sidebar-options-container .inf-sidebar-option:not(:last-child){
    margin-bottom: var(--spacing-1);
}

.inf-sidebar-option.sidebar-option-active{
    background-color: var(--inf-primary-blue-hover-color) !important;
}

.inf-sidebar-option:hover{
    background-color: var(--inf-theme-gray-200);
}

.inf-sidebar-profile{
    padding: 2rem 1.5rem;
    border-bottom: 1px solid var(--light-border-color);
}

.inf-sidebar-helpmenu{
    padding: 1rem 1.5rem;
    border-top: 1px solid var(--light-border-color);
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    font-size: 12px;
    color: var(--inf-theme-gray-600)
}

.inf-sidebar-helpmenu a{
    color: var(--inf-theme-gray-600)
}

.hover_blue:hover a{
    color: #0078ff;
}

.infurnia-card-container{
    width: calc( 100% - var(--infurnia-sidebar-width) );
    overflow: auto;
}

.infurnia-main-page{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: var(--layer-secondary-bg);
}

.infurnia-navbar-icons{
    /* margin-bottom: 0px; */
    /* padding:8px; */
    display:flex;
    align-items:center;
    justify-content: center;
    height:40px;
    border: none;
    background: none;
    outline: none;
}
.infurnia_dropdown_toggle{
    font-family: DM Sans;
}

.infurnia_dropdown_menu{
    font-family: DM Sans;
}
/* ImageCarousel css */

.infurnia-image-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.infurnia-image-carousel-card {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
}

.infurnia-image-carousel-card>img{
    z-index: 1;
}

.infurnia-image-carousel-card:hover>img{
    transform: scale(0.85);
}

.fixed-image-carousel-card>img{
    transform: scale(0.95, 1);
}

.fixed-image-carousel-card:hover>img{
    transform: scale(0.95, 1);
}

.infurnia-carousel-navigation-buttons{
    background-color: rgb(50, 51, 56, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    cursor: default;
}

.design_dropdown_menu .dropdown-item {
    padding: 6px 12px;
}
.dropdown_green_hover{
    color: black;
    background-color: white;
}

.dropdown_green_hover:hover{
    color: #1ABB9C;
    background-color: #eeeeee;
}
.composite_dropdown_menu{
    width:240px;
    max-height:240px;
    overflow-y:auto; 
    position:absolute !important; 
    left:0 !important; 
    top:40px !important;
}
.MuiOutlinedInput-input{
	padding:8px !important
}
.card_style{
	background: #FFFFFF;
	border: 1px solid #D5DAE0;
	border-radius: 4px;
}

.sub_category_active {
	background-color:rgba(16,112,202,0.09);
	color:#1070CA !important;
	font-family: Source_Sans_Pro-SemiBold;
}

.division_hover:hover {
	color:#1070CA !important;
}
.division_active {
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}

.sku_dropdown{
	background-color: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
	height:24px;
	width:24px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	margin-left: 6px !important;
	border: 0;
	font-size: 12px;
	color:#66788A; 
}

.sku_dropdown_list{
	background: #FFFFFF;
	box-shadow: 0 0 1px 0 rgba(67,90,111,0.47);
	border-radius: 5px;
	font-size: 14px;
}

.sku_dropdown_list_item{
	padding:6px 12px;
}

.group_dropdown{
	border: 1px solid #D1D7DA;
	background-color: #ffffff ;
	border-radius: 3px;
	box-shadow: none !important;
	height:27px;
	width:27px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	font-size: 12px;
}

.card_header_2{
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
}

.card_select{
	background: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
	border-radius: 3px !important;
    padding: 0px !important;
    height: 24px !important;
    font-size: 12px !important;
    border: 0
}
/*card hover*/
.on_hover_card_border:hover{
	border: 1px solid rgba(0,126,244,0.32);
}

.on_hover_card_border:hover .hover_1 .hover_2{
	color:#007EF4 !important;
	display: block !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
	display: block !important;
}


.on_hover_card_border:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}

/*list hover*/
.on_hover_card_border_list:hover{
	background: rgba(0,126,244,0.06);
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}
.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}


/*table tr,td*/

.list_table_tr{
	width:100%;
	border: 1px solid #F2F4F6;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	
}
.list_table_td{
	width:100%;
	height:50px;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	overflow-x: auto;
	white-space: nowrap;
}

.card_size_for_sku_properties{
	height:586px !important;
	width:700px !important;
}

/*for all checkbox*/
.checkbox_style{
	background: #FFFFFF !important;
	background-image: linear-gradient(179deg, #FFFFFF 0%, #F5F6F7 100%) !important;
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
}

.edit_properties_dropdown{
	background: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
	border-radius: 3px;
	width: 100%;
	font-size: 12px;
	color:#66788A;
}

.form-group{
	margin-bottom: 0;
}
/*.label_margin_5px{
	margin-bottom: 3px;

}*/

.disabled_css{
	background-color: #EEEEEE !important;
}
.nonactive_tab_pill_styles:hover {
	color:#007BFF !important;
}
.active_tab_pill_styles{
	background-color: rgba(4, 40, 68, 0.7) !important;
	color:white !important;
}
.active_tab_pill_styles:hover {
	color:white !important;
}

.on_hover_blur_image:hover .x1{
	margin: 0!important;
}
.on_hover_blur_image:hover .x1 .x2{
	display: flex !important;
}

.in_progress_style{
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0px;
    color: #A7A8B2;
}

.preview_done_style{
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0px;
    color: #23C16B;
}

.gpu_cpu_info{
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	color: #A7A8B2;
}

.border_table ,.border_td ,.border_th{
	border: 1px solid black;
	text-align: center;
}
.padding_th{
	padding:10px 0px;
	border:2px solid black;
	min-width: 80px;
}
.max_width_td{
	max-width: 150px !important;
}

.boq_schedule_dropdown_menu{
    width:calc(100% - 40px);
    max-height:500px;
    overflow-y:auto;
}
.multiSelectContainer ul{
	max-height:450px !important;
	height:auto;
}

.multiSelectContainer li:hover{
	background-color: #E6E9EE;
	color: rgba(50, 51, 56, 1);
}

.displayNone{
	display: block;
}


/* for_parameter */

.align_center{
	display: flex;
	align-items: center;
}

.commom_style{
	display: flex;
	align-items: center;
	font-size: 16px;
}
.common_input_style{
	font-size: 16px;
	width:100%;
	flex:5 1
}
.common_label_style{
	margin-right: 20px;
	flex:1 1
}

/* for Voilation modal */

.th_style_for_voilation{
	text-transform: capitalize;
	padding: 10px 5px;
}
.td_style_for_voilation{
	padding: 10px 5px;
	text-transform: capitalize;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncated_texture_name{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 120px;
}

.truncated_texture_name2{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 180px;
}

.mcp_color {
	width: 21px; /* Set the width of the input */
	height: 100%; /* Set the height of the input */
	padding: 0; /* Remove any padding */
	border: none; /* Remove any borders */
	outline: none; /* Remove the default focus outline */
	appearance: none; /* Remove browser default styling */
	cursor: pointer;
  }

  /* Additional CSS to target specific browsers */
  .mcp_color::-webkit-color-swatch-wrapper {
	padding: 0; /* Remove padding for WebKit browsers (Safari, Chrome) */
	border: none; /* Remove border for WebKit browsers (Safari, Chrome) */
  }

  .mcp_color::-moz-color-swatch {
	padding: 0; /* Remove padding for Firefox */
	border: none; /* Remove border for Firefox */
  }

  .color_hex_code {
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.62px;
	text-align: left;
	padding: 0 5px;
    border: solid 1px;
    cursor: pointer;
	height: 100%;
	border-left: none;
	display: flex;
	align-items: center;
  }

/* for Voilation modal */

.checkbox_background_hover{
	pointer-events: auto;
}
.checkbox_background_hover_before{
	pointer-events: none;
	height:30px;
	width:30px;
	border-radius:50%;
	display:flex;
	align-items:center;
	justify-content:center
}
.checkbox_background_hover_before:hover{
	background-color: #d9d9d9;
	opacity: .8;
}

.special_text {
	cursor: pointer;
	color:#007BFF;
	font-weight: 500;
}

.layerset_row {
	padding: 0px 16px 0px 16px;
}

.layerset_row:hover {
	background-color: #F5F6F8;
}

.layerset_row:hover .invisible_icon {
	visibility: visible;
}

.invisible_icon {
	visibility: hidden;
}

.active_layerset {
	background-color: #D3EDFD !important;
}

.table_style { 
	flex-direction: column; 
	width: 100% !important;
} 

.tr_style {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
}

.th_style {
	flex:1 1;
	height:40px;
	padding-left: 12px;
	font-size: 12px;
	font-weight: unset;
	/* border-right: 1px solid #f2f4f6; */
	color:#627386;
}

.td_style {
	flex:1 1;
	height:40px;
	padding-left: 12px;
	font-size: 12px;
	color:#22272e;
	width:100%;
	overflow-x:auto;
	white-space: nowrap;
	border-right: 1px solid #f2f4f6;
	text-transform:capitalize;
}

.input_container_publish_sheet{
	border: 1px solid #c5c7cf;
	padding-left: 12px;
	height: 32px;
	background-color: white;
}

.add_option_publish{
	background: rgba(16, 112, 202, 0.08);
	border-radius: 3px;
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.add_option_container_publish{
	display: flex;
	align-items: center;
	padding: 0px 6px;
	border-left: 1px solid #c5c7cf;
	height: 100%;
}

.vendor_container{
	width: 100%;
	padding-right: 12px;

}

.vendor_present_sku_container{
	width: 70%;
	/* padding-right: 12px; */
	display: flex;
	flex-wrap: wrap;
}
.checkbox_list_item{
	justify-content: flex-start;
	height: 36px;
	font-size: 14px;
}

.simple_white_button{
	padding: 6px 12px;
	font-size: 12px;
	font-weight: 500;
	border: 1px solid #c5c7cf;
	background-color: white;
	cursor: pointer;
}

.bucket_selection{
	padding: 20px;
	/* border: 1px solid; */
	background-color: white;
	margin-bottom: 20px;
	border-radius: 4px;
}

.sku_in_bucket{
	padding: 20px;
}

.sku_list_container{
	display: flex;
	margin: 0px -6px 0px -6px;
	flex-wrap: wrap;
	max-height: 250px;
	overflow: auto;
}

.sku_image{
	height: 93px;
	width: 126px;
	grid-gap: 32px;
	gap: 32px;
	/* margin-right: 12px; */
	/* margin-left: 6px; */
	/* margin-right: 6px; */
}

.sku_card_container{
	padding: 0px 6px;
	text-align: center;
}

.vendor_modal_regular_text{
	white-space: pre-wrap;
}

.active_possible_type{
	background-color: rgba(16, 112, 202, 0.09);
	color: rgba(16, 112, 202);
	padding: 6px 12px;
	margin-right: 16px;
	border-radius: 4px;
	cursor: pointer;
}

.possible_type{
	padding: 6px 12px;
	margin-right: 16px;
	border-radius: 4px;
	cursor: pointer;
}

.shortcuts_table_header{
	font-size: 14px;
	color: #A7A8B2;
	font-weight: 400;
}
.percentage-symbol{
	/* content: '%'; */
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
}

/* Common styles for all cards */
.render-card-container {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 16px;
	gap: 16px;
	justify-content: flex-start;
	padding-left: 16px;
	padding-right: 16px;
}


.render-card:hover {
	border: 1px solid #0078ff;
}

.render-pencil {
	display: none;
}

.render-card:hover .render-pencil {
	display: inline-block;
}

.render-card {
	min-width: 200px;
	/* Set your desired minimum width */
	background-color: white;
	/* padding: 16px; */
	box-sizing: border-box;
	flex-basis: calc(33.33% - 16px);
}

/* Media query for two render-cards per row */
@media (max-width: 1199px) {
	.render-card {
		flex-basis: calc(50% - 16px);
		/* Adjust based on the gap */
	}
}

/* Media query for one render-cards per row */
@media (max-width: 992px) {
	.render-card {
		flex-basis: 100%;
		/* Adjust based on the gap */
	}
}


.presentation-template-card-container {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 16px;
	gap: 16px;
	justify-content: flex-start;
	padding-left: 16px;
	padding-right: 16px;
}


.presentation-template-card:hover {
	outline: 1px solid #0078ff;
}

.presentation-template-card {
	min-width: 200px;
	/* Set your desired minimum width */
	background-color: white;
	/* padding: 16px; */
	box-sizing: border-box;
	cursor: pointer;
	flex-basis: calc(50% - 16px);
}

/* Media query for two presentation-template-cards per row */
@media (max-width: 1199px) {
	.presentation-template-card {
		flex-basis: calc(50% - 16px);
		/* Adjust based on the gap */
	}
}

/* Media query for two presentation-template-cards per row */
@media (max-width: 991px) {
	.presentation-template-card {
		flex-basis: calc(50% - 16px);
		/* flex-basis: 100%; */
		/* Adjust based on the gap */
	}
}

.presentation_image_card:hover {
	border: 1px solid #0078ff;
}

.presentation_image_card{
	min-width: 200px;
	/* Set your desired minimum width */
	border: 1px solid black;
	background-color: white;
	/* padding: 16px; */
	box-sizing: border-box;
	cursor: pointer;
	flex-basis: calc(25% - 16px);
}

.focus_input:focus{
	border: 1px solid #0078ff
}

/* Media query for three presentation_image_cards per row */
@media (max-width: 1199px) {
	.presentation_image_card {
		flex-basis: calc(33% - 16px);
		/* Adjust based on the gap */
	}
}

/* Media query for two presentation_image_cards per row */
@media (max-width: 991px) {
	.presentation_image_card {
		flex-basis: calc(50% - 16px);
		/* flex-basis: 100%; */
		/* Adjust based on the gap */
	}
}


.slider_post_processing {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .slider_post_processing:hover {
    opacity: 1;
  }
  
  .slider_post_processing::-webkit-slider-thumb {
    appearance: none;
    width: 8px;
    height: 8px;
    background:  #676878;
    cursor: pointer;
    border-radius: 50%;
  }
/* .fire_render_button{
	flex: 0 0 calc( 33.3% - 16px );
	text-align: center;
} */

.invalid_parameter_input{
	/* box-shadow: inset 0 0 2px var(--red_default); */
	outline: 1px solid var(--red_default);
	outline-offset: -2px;
}

.unselected_schedule_field_list_item{
	border-radius: 4px;
}
.unselected_schedule_field_list_item:hover{
	background-color: #cccccc;
}

.single_row_parent{
	height: 52px;
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	box-shadow: inset 0 -1px 0 0 #E4E7EB;
	cursor: pointer
}

.single_row_child .single_row_parent{
	box-shadow: none;
	border-left: 1px solid #C5C7CF;
	padding: 0px;
}

.single_row_child .single_row_parent::before{
	content: '-';
	color: #C5C7CF;
}

.single_row_child{
	padding-left: 24px;
}

.single_row_child .single_row_parent:last-child{
	border-bottom: 1px solid #C5C7CF;
}

.material_properties::-webkit-scrollbar-track{
    background-color: transparent;
    box-shadow: none;
}

.material_properties::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 4px;
}

.infurnia-radio .ant-radio-button-wrapper{
	flex: 1 1;
	text-align: center;
}

.layer-grid-item {
	padding: 10px; /* Padding for each cell */
}

.first-layer-grid-item {
	border-left: 1px solid black;
}

.layer_overriden_item {
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #3686F7;
}

.layer_default_item {
	font-family: DM Sans;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	color: #E6E9EE;
}

.layer_color_item {
font-family: DM Sans;
font-size: 11px;
font-weight: 400;
line-height: 14px;
letter-spacing: 0em;
text-align: left;
color: #676878;
}

.color_input_layer {
	width: 16px; /* Set the width of the input */
	height: 16px; /* Set the height of the input */
	padding: 0; /* Remove any padding */
	border: none; /* Remove any borders */
	outline: none; /* Remove the default focus outline */
	appearance: none; /* Remove browser default styling */
  }
  
  /* Additional CSS to target specific browsers */
  .color_input_layer::-webkit-color-swatch-wrapper {
	padding: 0; /* Remove padding for WebKit browsers (Safari, Chrome) */
	border: none; /* Remove border for WebKit browsers (Safari, Chrome) */
  }
  
  .color_input_layer::-moz-color-swatch {
	padding: 0; /* Remove padding for Firefox */
	border: none; /* Remove border for Firefox */
  }

  .styled-number-input {
	/* Ensure arrows are always visible */
	appearance: textfield;
	/* Hide the default number input */
	width: 4em;
	margin-right: 5px;
	overflow: hidden;
  }
  
  .styled-number-input::-webkit-inner-spin-button {
	opacity: 1;
  }
  .styled-number-input::-webkit-outer-spin-button {
	/* Show arrows */
	opacity: 1;
  }
  

.single_ss_hov:hov {
	background: transparent !important;
}

.layer-grid-item {
	border-right: 1px solid black; /* Right border for each cell except the last one in a row */
}

.layer-grid-container > div .layer-grid-item:last-child {
	border-right: none;
}

.layer-grid-container > div:first-child .layer-grid-item {
	border-top: 1px solid black; /* Top border for cells in the first row */
}

.layer-grid-container > div:last-child .layer-grid-item {
	border-bottom: 1px solid black; /* Bottom border for cells in the last row */
}

.sheet_card_item {
	border-radius: 4px;
    transition: background-color 0.3s;
}

.sheet_card_item.selected {
	background: linear-gradient(0deg, rgba(0, 120, 255, 0.1), rgba(0, 120, 255, 0.1)),
	linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
	border: 1px solid #0078FF;
}

.sheet_card_item.selected:hover {
	border: 1px solid #0078FF;
}

.sheet_card_item:hover {
	border: 1px solid #C5C7CF;
}

.invalid_input_cam{
    border: 1px solid red
}

.suggestion-list-cutlist{
	width: 60%;
	position: absolute;
	top: 32px;
	right: 0;
	text-align: left;
	font-size: 12px;
	color: black;
	padding: 4px;
	cursor: pointer;
	margin-top: 8px;
	margin-left: 8px;
	border: 1px solid #0078ff;
  }
  
  .single-suggestion-cutlist{
	height: 28px;
	display: flex;
	align-items: center;
  }
  
  .single-suggestion-cutlist:hover{
	background-color: rgba(24,144,255,.06);
  }

  .unsetModalContent{
	background-color: transparent;
	border: none;
  }
.cam_applicator_card{
	padding: 12px 12px 0px 12px;
	background: white;
	min-width: 0px;
	cursor: pointer;
	border-radius: 4px;
	display: flex ;
	flex-flow: column ;
	grid-gap: 8px;
	gap: 8px
}

.infurnia_panel_modal_card_row{
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	border: solid #C5C7CF;
	border-width: 0px 1px 0px 1px;
	justify-content: space-between;
	padding: 6px 6px;
}
.release_notes_feature_heading{
	font-weight: 500;
}

.release_notes_feature_heading:hover{
	font-weight: 600;
}

.infurnia_panel_modal_card_header{
	width: 100%;
	height: 54px;
	border-width: 0px 1px 0px 1px;
	display: flex;
	margin-top: 16px;
	padding: 8px 12px;
	background-color: #FFFFFF;
	border-color: #C5C7CF;
	align-items: center;
	justify-content: space-between;
}

.connector_set_modal_filters{
	background-color: white;
	padding: 16px;
	/* margin: calc( -1 * var(--inf-modal-body-padding, 16px) ) 0px calc( -1 * var(--inf-modal-body-padding, 16px) ) calc( -1 * var(--inf-modal-body-padding, 16px) ); */
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
	width: 280px;
}

.connector_set_modal_filters_container{

}

.connector_set_modal_filters_footer{
	display: flex;
	grid-gap: 32px;
	gap: 32px;
	justify-content: space-between;
	margin-top: auto;
}

.connector_set_modal_data{
	flex: 1 1;
	min-height: 250px;
	overflow: auto;
}

.connector_set_modal_connector_card{
	border-radius: 8px;
	background-color: #0078FF0D;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.connector_set_modal_connector_card_footer{
	padding: 8px 16px;
	color: #0078ff;
}

.connector_set_modal_data_container{
	flex: 1 1;
	min-width: 0px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
}

.connector_set_modal_data_container_header{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.connector_set_modal_data_container_nav{
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 12px;
	gap: 12px;
}

.connector_set_modal_data_container_nav_info{
	display: flex;
	grid-gap: 16px;
	gap: 16px;
	padding: 4px 8px;
	white-space: nowrap;
	font-size: 12px;
}

.connector_set_selection_card{
	display: flex;
	grid-gap: 4px;
	gap: 4px;
	background: white;
	border-radius: 6px;
	overflow: hidden;
	padding: 4px 12px;
	cursor: pointer;
}

.confirm_scenarios_card{
	background-color: white;
	padding: 24px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	grid-gap: 8px;
	gap: 8px;
	border: 1px solid #A7A8B2;
	cursor: pointer;
}

#board_layout_view{
	display: flex;
	flex-direction: column;
	grid-gap: 12px;
	gap: 12px;
	position: absolute;
	top: var(--infurnia-navbar-height, 52px);
	height: calc(100% - var(--infurnia-navbar-height, 52px));
	width: 100%;
	padding: 0.75rem;
	background-color: #eceff1;
	z-index: 10;
	text-align: left;
}

.board_layout_info_card{
	border: 1px solid var( --light-border-color, #C5C7CF);
	padding: 4px 8px;
	border-radius: 6px;
	background-color: white;
}

.inf_cam_flow_modular_units_filter_control .inf_cam_flow_modular_units_filter_info{
	display: none;
}
.single_column:hover{
    background-color: #E6E9EE !important;
}
.inf_model_outer_div{
    background-color: black;
    opacity: 0.05;
    z-index:5;
    height:100vh;
    width:100vw;
    position: fixed;
    top:0px;
    left:0px;
}

.badge_container{
    font-size:12px;
    width:100%;
    height:32px;
    position:relative;
    color: rgb(67, 90, 111);
    border-radius: 3px;
    border: 1px solid #ced4da;
    background-color:white;
    display:flex;
    align-items:center;
    overflow:auto
}

.options_search_container{
    z-index:5;
    background-color:white;
    border: 1px solid #ced4da;
    position:absolute;
    max-width:100%
}

.options_container{
    width:100%;
    max-height:150px;
    overflow:auto;
    font-size: 12px;
}

.single_option{
    display:flex;
    align-items:center;
    height:30px;
    font-size:"12px";
    padding:5px 10px;
    cursor:pointer 
}

.option_text{
    max-width:300px;
    overflow:auto;
    white-space:nowrap;
    text-transform:capitalize;
}

.single_badge{
    display:flex;
    align-items:center;
    font-size:"12px";
    margin:0px 10px;
    padding:0px 5px;
    border-radius:5px;
    background-color:#f2f2f2;
    max-width: 75%;
}
.badge_text{
    /* max-width:80px; */
    overflow:auto;
    white-space:nowrap;
    margin-right:5px;
    text-transform:capitalize;
    pointer-events: auto;
}

.badge_text:hover {
    pointer-events: none;
}

.search_input_box{
    width:100%;
    border:0;
    font-size:12px;
    padding:5px 10px;
    height:32px;
    outline: none;
}

.badge_text::-webkit-scrollbar {
    height: 0px;
}

.option_text::-webkit-scrollbar {
    height: 0px;
}
.inf_nav_tabs_blue_background {
    margin: 4px 0px;
    padding: 0px 4px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
    border-bottom: 2px solid #E4E7EC;
}

.inf_nav_tabs_blue_background.active {
    margin: 4px 0px;
    padding: 0px 4px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
    background: #E8EFFD;
}


.inf_nav_tabs_blue_background_1 {
    padding: 4px 16px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
}

.inf_nav_tabs_blue_background_1.active {
    font-weight: 700;
    color: #3A63D4;
    background: rgba(16, 112, 202, 0.09);
}


.inf_nav_tabs_transparent {
    padding: 4px 16px;
    font-size: 14px;
    color: #676878;
    cursor: pointer;
}

.inf_nav_tabs_transparent.active {
    font-weight: 700;
    color: #323338;
}
.infurnia-table {
    width: 100%;
    border-collapse: collapse;
}

.infurnia-table th,
.infurnia-table td {
    padding: 8px;
}

.infurnia-table-row.error_in_infurnia_table_row {
    background-color: #ffe6e6 !important;
}

.infurnia-table th {
    /* background-color: #f2f2f2; */
    font-weight: 500;
    text-align: left;
    color: #A7A8B2;
}

.infurnia-table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.infurnia-table-bordered {
    border: 1px solid #ccc;
}

.infurnia-table-dark {
    color: #fff;
    background-color: #343a40;
}

.infurnia-table-dark th,
.infurnia-table-dark td {
    border-color: #454d55;
}

.infurnia-table-hover tbody tr:hover {
    background-color: var(--inf-primary-blue-hover-color);
}

.table-responsive {
    overflow-x: auto;
}

.infurnia-table-bordered .infurnia-table-row:not(:last-child) {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-row-separated .infurnia-table-row:not(:last-child) {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-bordered .infurnia-table-cell:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-bordered .infurnia-table-header:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-column-separated .infurnia-table-cell:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-column-separated .infurnia-table-header:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-header-row {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-container::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}

.infurnia-table-container::-webkit-scrollbar-track{
    background-color: #E6E9EE;
    box-shadow: none;
}

/* .infurnia-table-container::-webkit-scrollbar-corner{
    background-color: #E6E9EE;
} */

.infurnia-table-container::-webkit-scrollbar-thumb{
    background-color: #A7A8B2;
    border-radius: 4px;
}

.infurnia-table-container::-webkit-scrollbar-thumb:hover{
    background-color: #676878;
}
.infurnia_collapse_header{
    background-color: #f5f6f8;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	/* border-bottom: 1px solid #E4E7EB; */
	font-weight: 600;
	font-size: 12px;
}

.infurnia_collapse_container{
    background-color: white;
	font-size: 12px;
	height: 0px;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out;
	max-height: 0px;
}

.infurnia_collapse_container.uncollapsed{
	border: 1px solid #E6E9EE;
	height: auto;
	max-height: 10000px;
	/* transition-delay: 0.1s; */
}

.infurnia_collapse_content{
	padding: 10px 20px;
}
.inf_nav_sidebar_transparent.active{
    color: #3872C5;
    background: #E3EAF4;
    border-right: 2px solid #3872C5;
}

.inf_nav_sidebar_transparent{
    height: 40px;
    color: #676878;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    cursor: pointer;
}

.inf_nav_sidebar_transparent:hover{
    background: #E3EAF4;
}
.material_dropdown_menu {
    width: 295px;
    height: 300px;
    position: absolute !important;
    left: 124px !important;
    top: 110px !important;
}

.material_dropdown_menu_bottom {
    width: 295px;
    height: 300px;
    position: absolute !important;
    left: 124px !important;
}

.mcd_dd_pos {
    left: 210px !important;
     top: -40px !important;
}

.mcd_dd_pos_bottom {
    left: 210px !important;
    top: 200px !important;
}

.unset_boxshadow:focus {
    box-shadow: none !important;
}

.on_hover:hover{
    box-shadow: 0px 0px 7px #314456 inset;
}

.unit_disabled{
    opacity:0.3;
    cursor:not-allowed;
}

.tooltip_hover:hover {
    visibility: visible;
}
.tooltip3 {
    visibility: hidden;
    width: 100px;
    background-color: #cccccc;
    color: #000000;
    text-align: center;
    padding: 5px 0;
    font-size: small;
    opacity: 1;
    position: absolute;
    right: 100px;
    top: -20%;
    z-index: 1;
}
.hover_leftmenu_green_border{
    box-shadow: rgb(0 0 0 / 25%) 0 1px 0, inset rgb(255 255 255 / 16%) 0 1px 0;
    border-right: 3px solid rgba(230, 233, 238, 1) !important;
}

.createcard_input_container_new.input_small input[type="text"], 
.createcard_input_container_new.input_small input[type="number"], 
.createcard_input_container_new.input_small input[type="color"],
.createcard_input_container_new.input_small select {
    height: 100% !important;
    flex: 1 1 !important;
    min-width: 0px !important;
    border: none !important;
    font-size: 12px !important;
    outline: none !important;
    padding: 0px !important;
}

.input_small{
    height: 22px !important;
}

.createcard_input_container_new{
    background-color: #ffffff !important;
    border: 0.6px solid #CED4D9 !important;
    border-radius: 2px !important;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0px 4px !important;
    grid-gap: 4px;
    gap: 4px;
    max-width: 100%;
    width: -webkit-fill-available;
    flex: 1 1;
}

.createcard_input_container_new:focus-within{
    border: 1px solid #0078ff !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container_new.accept:focus-within{
    border: 1px solid #23C16B !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container_new.reject:focus-within{
    border: 1px solid var(--red_default) !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}


.createcard_input_container{
    background-color: #ffffff;
    border: 1px solid #CED4D9;
    border-radius: 2px;
}

.createcard_input_container:focus-within{
    border: 1px solid #0078ff !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container.accept:focus-within{
    border: 1px solid #23C16B !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.createcard_input_container.reject:focus-within{
    border: 1px solid var(--red_default) !important;
    /* box-shadow: 0 0 0 1px #0078ff; */
}

.panel_flex_property_wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 4px 12px;
    gap: 4px 12px;
}

.panel_section{
    --panel_section_item_gap_x: 8px;
    --panel_section_item_padding_x: 0px;
    background-color: transparent;
    text-align: left;
    /* padding: 4px 8px 4px 8px; */
    padding: 10px 8px 10px 8px;
    border-bottom: 1px solid #E6E9EE;
    position: relative;
    line-height: normal;
}

/* .panel_section .panel_section:last-child{
    border-bottom:none;
} */

.panel_section_menu_item:last-child .panel_section{
    border: none;
}

.panel_section_menu_item:last-child .panel_sub_section{
    padding: 0px;
}

.panel_card{
    padding: 0px var(--panel_section_item_padding_x);
}

/* .panel_section::after{
    content: '';
    height: 1px;
    width: 270px;
    position: absolute;
    background-color: #000;
    bottom: 0px;
    left: 0px;
    margin: 0px -8px;
} */

/* .panel_section .panel_section{
    padding: 4px 0px;
} */

.panel_section_menu, .panel_sub_section_menu{ 
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px var(--panel_section_item_gap_x, 8px) ;
    gap: 8px var(--panel_section_item_gap_x, 8px) ;
}

.panel_sub_section_menu{
    /* padding: 0px 0px 0px 4px; */
    --panel_section_item_gap_x: 8px;
}

.panel_section_menu{
    padding: 0px 4px 0px 12px;
}

.panel_sub_section {
    padding-bottom: 8px;
}

.panel_section_header, .panel_sub_section_header{
    padding: 0px var(--panel_section_item_padding_x);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.panel_section_header{
    margin-bottom: 12px;
    cursor: pointer;
}

.panel_sub_section_header{
    margin-bottom: 6px;
}

.panel_section_header.collapsed{
    margin-bottom: 0px;
}

.panel_section_header::before{
    content: '\25BE';
    margin-right: 4px;
    line-height: 1;
}

.panel_section_header.panel_empty_submenu::before{
    opacity: 0.5;
}

.panel_section_header.collapsed::before{
    content: '\25B8';
    margin-right: 4px;
    line-height: 1;
}

.panel_section_heading_text{
    /* text-decoration: underline; */
    color: #323338;
    line-height: normal;
    font-style: italic;
    font-weight: 600;
    font-size: 13px;
    flex: 1 1;
    min-width: 0px;
}

.panel_section_heading_text:hover{
    color: #000;
}

.panel_section_header.panel_empty_submenu .panel_section_heading_text{
    opacity: 0.5;
}

.panel_sub_section_heading_text{
    color: #323338;
    /* text-decoration: underline; */
    line-height: normal;
    /* font-style: italic; */
    font-weight: 500;
    flex: 1 1;
    min-width: 0px;
}

.panel_section_header_2{
    padding-top: 2px;
    padding-bottom: 4px;
    text-decoration: underline;
    line-height: normal;
    font-style: italic;
    font-weight: 500
}

.panel_selectable_button{
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.panel_selectable_button.active{
    background: #E6E9EE;
}

.panel_selectable_button:hover{
    background: #E6E9EE80;
}

.material_selector_new_buttons{
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    /* align-self: end; */
    height: 22px;
    align-items: center;
    /* visibility: hidden; */
    /* opacity: 0; */
}

.material_selector_new_buttons .infurnia_icon {
    opacity: 0;
    cursor: pointer;
}

.material_select_new:hover .material_selector_new_buttons .infurnia_icon{
    /* visibility: visible; */
    opacity: 1;
}

.material_selector_new_buttons .infurnia_icon.option_dropdown_open{
    /* visibility: visible; */
    opacity: 1;
}

.material_selector_new_buttons > * {
    cursor: pointer;
}

.panel_wrap_container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.panel_multi_component_input_container {
    width: 100%;
    border: 1px solid ;
    display: flex;
}

.panel_multi_component_single_input_container{
    flex:1 1;
    min-width: 0;
    display: flex;
}

.panel_multi_component_single_input_container > * {
    flex: 1 1;
    min-width: 0;
}

.panel_multi_component_single_input_container > input,select {
    border: none;
}


.show_on_list_item_hover{
    display: none;
}

.panel_checkbox_input_new:hover{
    color: #0078ff;
}

.panel_checkbox_input_new{
    cursor: pointer;
}

.panel_list_item_new{
    /* padding: 4px 0px; */
}

.panel_list_item_new:hover{
    color: #0078ff;
}

.panel_list_item_new.danger:hover{
    color: #FF5247;
}

.panel_list_item_new:hover .show_on_list_item_hover{
    display: block;
}

/* Rotation input */
.RI----------RI{
    background-color: transparent;
}

/* Intensity input */
.II----------II{
    background-color: transparent;
    width: 100%;
    padding: 15px 0px 2px 0px;
}

/* Select Input */
.SI----------SI{
    background-color: transparent;
}

/* Dimension Input */
.dI----------dI{
    background-color: transparent;
}

/* List Item */
.LI----------LI{
    background-color: transparent;
    /* height: 36px; */
    font-weight: 500;
}

/* Text Input */
.TI----------TI{
    background-color: transparent;
}

/* Visibility Input */
.VI----------VI{
    background-color: transparent;
}

/* Color Picker */
.CP----------CP{
    background-color: transparent;
}

/* Material Select */
.MS----------MS{
    background-color: transparent;
}

/* Composite Select */
.CS----------CS{
    background-color: transparent;
}

/* Checkbox */
.CB----------CB{
    background-color: transparent;
}

/* Text Info */
.TI----------TI{
    background-color: transparent;
}

/* Select and Dimension Input */
.SDI----------SDI{
    background-color: transparent;
}

/* Toggle */
.TG----------TG{
    background-color: transparent;
}

/* Button Array */
.BA----------BA{
    background-color: transparent;
}

/* Top Level Toolbar */
.TT----------TT{
    background-color: transparent;
}

.CP----------CP .createcard_input_container input{
    padding: 0 !important;
}

.SI----------SI .createcard_input_container{
    padding-right: 6px !important;
}

.PS----------PS:hover{
    background-color: #F5F6F8;
}

.PS----------PS:hover .show_on_hover_sheets{
    visibility: visible;
}

.PS----------PS:not(:hover) .show_on_hover_sheets:not(:focus){
    visibility: hidden;
}

.show_on_hover_sheets:focus {
    visibility: visible;
    background: #E6E9EE !important;
}

.options_font {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #676878;
}

.PS----------PS{
    width: 100%;
    margin: 0;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0px 12px;
}

.CT----------CT{
    position: unset;
}

.TT----------TT{
    position: unset;
}

.dropdown_heading_panel{
    font-size: 12px;
    font-weight: 500;
}

.section_heading_panel{
    font-size: 12px;
    font-weight: 500;
    color: #676878;
}

.dropdown_panel{
    height: 36px;
    cursor: pointer;
}

.active_top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid #3D434D;
    border-right: 1px solid #6c7789;
    background-color: #616B7A;
    cursor: pointer;
}

.mes_design_edit_mode .active_top_tab_panel{
    background-color: #303030;
}

.active_top_tab_panel_search{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    /* border-bottom: 3px solid #3D434D;
    border-right: 1px solid #6c7789; */
    background-color: white;
    cursor: pointer;
}

.top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789;
    cursor: pointer;
}

.top_tab_panel:hover{
    border-bottom: 3px solid #616B7A;
}

.LI----------LI:hover .list_item_blue_hover{
    color: #0078ff !important;
}

.panel_card:hover .list_item_blue_hover{
    color: #0078ff !important;
}

.list_item_visible_hover{
    visibility: hidden;
}

.LI----------LI:hover .list_item_visible_hover{
    visibility: visible;
}

.button_panel{
    align-items: center;
    cursor: pointer;
}

.overriden_layer_item {
    background: #FFFAE6;
}

.overriden_layer_item:hover {
    background: #F5F2E6;
}

.normal_layer_item:hover {
    background: #F5F6F8;
}

.disable_feedback:hover {
    background-color: unset !important;
}

.tab_index_panel{
    font-size: 12px;
    padding: 0px 6px;
    border-right: 1px solid #CED4D9;
    display: flex;
    align-items: center;
    color: #a7a8b2;
    min-width: 32px;
    justify-content: center;
}

.disabled_top_tab_panel{
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789;
    cursor:not-allowed;
    opacity: 0.5;
}

.top_name_panel{
    height: 40px;
    /* width: 40px; */
    align-items: center;
    /* justify-content: center; */
    display: flex;
    /* border-bottom: 3px solid transparent;
    border-right: 1px solid #6c7789; */
    cursor: pointer;
    padding-left: 8px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.rename_group_input_container{
    width: 100%;
    padding: 8px;
    font-size: 12px;
    height: 40px;
}

.rename_group{
    /* all: unset; */
    height: 100%;
    font-size: 12px;
    border-radius: 2px;
}

.pencil-svg path,
.pencil-svg line,
.pencil-svg polyline,
.pencil-svg rect,
.pencil-svg circle {
  /* stroke: black; */
  fill: black;
}
#panel_search_input{
    /* flex: 1; */
    margin: 0;
    width: calc( 100% - 90px );
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    appearance: none;
    width: 6px;
    height: 12px;
    background:  #676878;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #eef2f0;
    cursor: pointer;
  }
  

  /* comments */
  .comment-input {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #0078ff;
      max-height: 200px;
      overflow: auto;
      background-color: white;
  }

  .comment-input textarea {
      flex: 1 1;
      min-height: 36px;
      padding: 0px;
      border: none;
      outline: none;
      resize: vertical;
      height: auto;
      overflow: visible;
  }

  .comment-input button {
      padding: 8px 12px;
      margin-left: 8px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }

  .comment-input button:hover {
      background-color: #0056b3;
  }

  .suggestion-list{
    min-width: 125px;
    text-align: left;
    font-size: 12px;
    color: black;
    padding: 4px;
    cursor: pointer;
    margin-top: 8px;
    margin-left: 8px;
    border: 1px solid #0078ff;
  }

  .single-suggestion{
    height: 28px;
    display: flex;
    align-items: center;
  }

  .single-suggestion:hover{
    background-color: rgba(24,144,255,.06);
  }

.inf-presets-color-picker {
    display: flex;
    justify-content: center;
}

.inf-presets-color-grid {
    display: grid;
    grid-template-columns: repeat(12, 12px);
    /* Adjust column count as needed */
    grid-gap: 5px;
}

.inf-presets-color-option {
    width: 12px;
    height: 12px;
    cursor: pointer;
    border: 1px solid var(--light-border-color);
    border-radius: 1px;
}

.inf-presets-color-option.selected {
    border: 1px solid #0078ff;

}

.image_map_select_dropdown {
    width: calc( var(--panel_width) - 16px );
    max-height: 240px;
    overflow-y: auto;
    position: absolute !important;
    margin-top: 42px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.5);
    /* left:0 !important; 
    top:40px !important; */
}

.sheet-dropdown-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin: 6px 10px;
  }

  /* .panel_numeric_integer_input:focus {
    color: black;
  } */
  
  .panel_numeric_integer_input:not(:focus-within)::after {
    content: attr(data-decimal);
    color: gray;
    display: inline-block;
    /* background-color: #0078ff; */
    font-size: 12px;
    opacity: 1;
  }
  
  .createcard_input_container::-webkit-scrollbar{
    height: 0px;
  }

  .panel_button_array > :not(:last-child){
    margin-right: 8px;
  }

.panel_tree_parent_header{
    padding: 8px 12px;
}

.panel_tree_leaf{
    padding: 8px 12px;
}

.panel_tree_leaf_indicator{
    /* margin-left: 4px; */
    transform: translateX(5px);
    /* display: inline-block; */
    position: relative;
}
.panel_tree_leaf_indicator::before{
    /* border: 1px solid; */
    /* height: 20px; */
    content: '';
    top: 0px;
    height: 36px;
    position: absolute;
    border-inline-end: 1px solid var(--ant-color-border, #000);
}
.panel_tree_leaf_indicator::after{
    content: '';
    content: '';
    top: 0px;
    height: 18px;
    width: 4px;
    position: absolute;
    border-bottom: 1px solid var(--ant-color-border, #000);
}

.panel_tree_parent_collapse > .panel_tree_item_container.last_tree_item > .panel_tree_leaf_indicator_container > .panel_tree_leaf_indicator::before{
    height: 18px !important;
}

.panel_tree_parent_collapse > .panel_tree_collapse_indicator_container > .panel_tree_collapse_indicator::before{
    height: 100%;
    content: '';
    position: absolute;
    border-right: 1px solid;
    transform: translateX(-7px);
}

.panel_tree_item{
    padding: 4px 8px;
    border-radius: 4px;
    flex: 1 1;
    min-width: 40px;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.panel_tree_item:hover{
    background-color: rgba(54, 134, 247, 0.1);
    color: #0078ff;
}

.root_tree_item{
    padding: 4px 8px 4px 0px;
    border-radius: 4px;
    flex: 1 1;
    min-width: 40px;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.BI----------BI.highlight_and_fade_bi{
    animation: blink-box-shadow 1.5s 2 ease-in-out;
}

@keyframes blink-box-shadow {
    25% {box-shadow: inset 0px 0px 0px 2px #0078FF80;}
    75% {box-shadow: inset 0px 0px 0px 2px #0078FF80;}
}

.edge_band_options_dropdown_toggle{
    color: black;
    font-size: 12px;
    background-color: #E6E9EE80;
    padding: 0px 4px;
    border-radius: 2px;
    white-space: nowrap;
}

.edge_band_options_dropdown_toggle_text{
    flex: 1 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.panel_edgeband_item{
    cursor: pointer;
    border: 1px solid #323338;
    border-radius: 2px;
    /* background-color: yellow; */
    
}

.panel_edgeband_item.no_edge_band{
    border: 1px dashed #A7A8B2;
}

.panel_edgeband_item.selected{
    border: 2px solid #0078ff;
}

.panel_edgeband_item.selected:hover{
    border: 2px solid #0078ff;
}

.panel_edgeband_item.left.selected::before{
    content: '';
    position: absolute;
    height: 2px;
    background: #0078ff;
    left: calc(var(--panel_edgeband_item_thickness) - 2px);
    width: 16px;
    top: 50%;
}

.panel_edgeband_item.left>div{
    max-width: calc(0.8*var(--panel_edgeband_visualizer_height) - 1.6*var(--panel_edgeband_item_gap_y));
    transform: rotate(270deg);
}

.panel_edgeband_item.right>div{
    max-width: calc(0.8*var(--panel_edgeband_visualizer_height) - 1.6*var(--panel_edgeband_item_gap_y));
    transform: rotate(270deg);
}

.panel_edgeband_item.front>div{
    max-width: 80%;
}

.panel_edgeband_item.back>div{
    max-width: 80%;
}

.panel_edgeband_item.right.selected::before{
    content: '';
    position: absolute;
    height: 2px;
    background: #0078ff;
    right: calc(var(--panel_edgeband_item_thickness) - 2px);
    width: 16px;
    top: 50%;
}

.panel_edgeband_item.front.selected::before{
    content: '';
    position: absolute;
    width: 2px;
    background: #0078ff;
    top: calc(var(--panel_edgeband_item_thickness) - 2px);
    height: 16px;
    left: 50%;
}

.panel_edgeband_item.back.selected::before{
    content: '';
    position: absolute;
    width: 2px;
    background: #0078ff;
    bottom: calc(var(--panel_edgeband_item_thickness) - 2px);
    height: 16px;
    left: 50%;
}

.panel_edgeband_item.add_edge_band{
    border: 1px dashed #A7A8B2;
    background-color: #F5F6F8;
    background-image: url('/resources/nav_icons_new/add_icon.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 14px;
}

/* .panel_edgeband_item.invisible_edge_band::after{
    content: '';
    position: absolute;
    background: repeating-linear-gradient(
                Angle of the diagonal lines
                135deg, 
                Color of the slashes
                #f0f0f0, 
                End color of the slashes
                #f0f0f0 2px, 
                Start color of the background
                transparent 2px, 
                End color of the background
                transparent 8px 
            );
    width: 100%;
    height: 100%;
    
} */

.panel_edgeband_item:hover{
    border: 1px solid #0078ff;
}

.panel_edgeband_visualiser{
    --panel_edgeband_item_thickness: 24px;
    --panel_edgeband_visualizer_height: 108px;
    --panel_edgeband_item_gap_x: 32px;
    --panel_edgeband_item_gap_y: calc( var(--panel_edgeband_item_thickness) / 2 );

    width: 100%;
    position: relative;
    height: var(--panel_edgeband_visualizer_height, 108px);
}

.panel_edgeband_info_text{
    padding: 4px;
    color: #A7A8B2
}

.panel_edgeband_actions_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #F5F6F8;
    width: calc(100% - var(--panel_edgeband_item_gap_x) - var(--panel_edgeband_item_gap_x));
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    justify-content: space-between;
    border: 2px solid #0078ff;
}
.hatching_dropdown_menu{
    width:240px;
    max-height:240px;
    overflow-y:auto; 
    position:absolute !important;
    margin-top: 4px;
    /* left:0 !important; 
    top:40px !important; */
}
.infurnia-button {
  padding: 6px 12px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 72px;
  position: relative;
}

.infurnia-button:disabled{
  opacity: 0.5;
}

.infurnia-button:active {
  transform: scale(0.95);
}

.infurnia-button:hover {
  opacity: 0.9;
}

.infurnia-button:disabled:hover {
  opacity: 0.5;
}

.infurnia-button.outline {
  background-color: transparent;
  color: #000;
  border: 1px solid;
}

.infurnia-button.small {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.25rem;
}

.infurnia-button.medium {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.25rem;
}

.infurnia-button.large {
  padding: 8px 16px;
  font-size: 18px;
  line-height: 1.75rem;
}

.infurnia-button.primary {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #0078ff;
}

.infurnia-button.secondary {
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid
}

.infurnia-button.success {
  background-color: #28a745;
  color: #fff;
}

.infurnia-button.warning {
  background-color: #f9dade;
  color: #ff5247;
}

.infurnia-button.ghost {
  padding: 0px;
  min-width: unset;
}

.infurnia-button.ghost.primary {
  background-color: transparent;
  color: #007bff;
  border: none;
}

.infurnia-button.ghost.secondary {
  background-color: transparent;
  color: #000000;
  border: none
}

.infurnia-button.ghost.success {
  background-color: transparent;
  color: #28a745;
  border: none;
}

.infurnia-button.ghost.warning {
  background-color: transparent;
  color: #ff5247;
  border: none
}

.infurnia-button.outline.primary {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.infurnia-button.outline.secondary {
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000
}

.infurnia-button.outline.success {
  background-color: transparent;
  color: #28a745;
  border: 1px solid #28a745;
}

.infurnia-button.outline.warning {
  background-color: transparent;
  color: #ff5247;
  border: 1px solid #f9dade
}
.cam_applicator_card{
	padding: 12px 12px 0px 12px;
	background: white;
	min-width: 0px;
	cursor: pointer;
	border-radius: 4px;
	display: flex ;
	flex-flow: column ;
	grid-gap: 8px;
	gap: 8px
}

.rule_applicator_card{
	display: flex;
	align-items: center;
	grid-gap: 32px;
	gap: 32px;
	padding: 16px 32px;
}

.rule_applicator_card_info{
	align-self: baseline;
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
	width: 320px;
}

.rule_applicator_card_sku_info{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cam_applicator_input{
	border: 1px solid #a7a8b3;
	padding: 4px 8px;
	border-radius: 4px;
	outline: none;
	box-shadow: none;
}

.cam_applicator_input:focus{
	border: 1px solid #0078ff;
}
/* rolling back section highlight */
/* .infurnia_toolbar_section_dropdown{
    display: flex;
    border-bottom: 2px solid #788394;
    border-right: 2px solid transparent;
    border-left: 3px solid transparent;
} */

.infurnia_toolbar_section{
    display: flex;
}

/* rolling back section highlight */
/* .show_infurnia_section_highlight{
    border-bottom: 2px solid #788394;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
} */
.single_item_div:hover .dropdown_icon_hover{
    color: #1ABB9C !important;
    display: block;
}

.single_item_div:hover .dropdown_text_hover{
    color: #1ABB9C !important;
}
.dropdown_icon_hover{
    display: none;
}

.filler_empty_views {
    min-height: 32px;
    background: #F5F6F8;
    border: 1px dashed #C5C7CF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #A7A8B2;
}
.black_white_nav_item .nav-link {
    color: black;
}

.active_view{
    color: #323338 !important;
    background-color: white;
}
.active_view .x1{
    color: #323338 !important;
}
.active_view .x1 .x2{
    color: #323338 !important;
}

.inactive_view_container:hover{
    background-color: #E1E4EA !important;
}

.inactive_view{
    color: #323338 !important;
    /* flex-shrink: 1;
    min-width: 32px; */
}

.inactive_view_container{
    background-color: #EDEFF2 !important;
    /* min-width: 95px; */
    border-top: 1px solid #8E8F9E;
}


.close_icon_on_hover{
    display: none;
}

.inactive_view_container:hover .close_icon_on_hover{
    display: unset;
}

.solid_lock{
    color: #676878;
}

/* .solid_lock:hover{
    color: #4597F7;
    display: none !important;
    cursor: pointer;
} */

.solid_unlock{
    color: #676878;
    display: none !important;
}

.view_settings_icon:hover{
    color: #0078FF;
}

.view_settings_icon{
    color: #676878;
}

.close_view{
    color: #676878;
}

.close_view:hover{
    color: #FF5247;
}

/* .solid_unlock:hover{
    display: flex !important;
    color: #4597F7;
    cursor: pointer;
} */

.solid_lock_unlock:hover .solid_lock{
    display: none !important;
}

.solid_lock_unlock:hover .solid_unlock{
    display: flex !important;
    color: #4597F7;
    cursor: pointer;

}



/* .inactive_view_container:hover{
    flex-shrink: 0;
    min-width: 32px;
    background-color: #E1E4EA !important;
} */

.active_view_container{
    border-top: 2px solid #E6E9EE;
    border-bottom: 2px solid #8E8F9E
    /*  box-shadow: 0px 1px 2px 0px #0078FF80; */
}
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap'); */

.ref_level_dropdown_menu{
    width:240px;
    max-height:240px;
    overflow-y:auto; 
    position:absolute !important; 
    left:0 !important; 
    top:40px !important;
}

.panel_green_btn{
    border: 0px;
    padding: 8px 16px;
    font-size: 12px;
    background-color: #0078FF;
    color: rgba(255, 255, 255);
    text-transform:capitalize;
    /* letter-spacing: 0; */
    /* will-change: box-shadow, transform;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%); */
}

.panel_green_btn:hover{
    background-color: #0078FF !important;
}

.panel_color_green_arrow{
    color: #00aa9a !important;
}

.panel_green_btn:focus {
    background-color: #0078FF!important;
    border: 0!important;
    outline: 0 !important;
}
.panel_green_btn:focus-visible {    
    outline: 0 !important;
}

.panel_disabled{
    opacity:0.5;
    cursor:not-allowed !important;
    pointer-events: none;
}

.panel_disabled_opacity{
    opacity:0.5;
    cursor:not-allowed !important;
}

.hover_enabled_green_border:hover{
    background-color: #F5F6F8 !important;
    border-right: 3px solid #0078FF !important;
}

.lines2_elipsis{
    /* display: block; */
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
}

.lines1_elipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.test_cursor {
  cursor: url('/resources/toolbar_icons_new/extrude.svg'), crosshair !important;
}

.underline_onhover:hover{
  text-decoration: underline;
}

.bold_onhover:hover{
  font-weight: 700;
}

.blue_highlight_focuswithin:focus-within{
  /* box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%); */
  border: 1px solid #0078FF !important;
}


/* .toggleSwitch span span {
    display: none;
  }
  
  @media only screen {
    .toggleSwitch {
      display: inline-block;
      height: 18px;
      position: relative;
      overflow: visible;
      padding: 0;
      margin-left: 50px;
      cursor: pointer;
      width: 40px
    }
    .toggleSwitch * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .toggleSwitch label,
    .toggleSwitch > span {
      line-height: 20px;
      height: 20px;
      vertical-align: middle;
    }
    .toggleSwitch input:focus ~ a,
    .toggleSwitch input:focus + label {
      outline: none;
    }
    .toggleSwitch label {
      position: relative;
      z-index: 3;
      display: block;
      width: 100%;
    }
    .toggleSwitch input {
      position: absolute;
      opacity: 0;
      z-index: 5;
    }
    .toggleSwitch > span {
      position: absolute;
      left: -50px;
      width: 100%;
      margin: 0;
      padding-right: 50px;
      text-align: left;
      white-space: nowrap;
    }
    .toggleSwitch > span span {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      display: block;
      width: 50%;
      margin-left: 50px;
      text-align: left;
      font-size: 0.9em;
      width: 100%;
      left: 15%;
      top: -1px;
      opacity: 0;
    }
    .toggleSwitch a {
      position: absolute;
      right: 50%;
      z-index: 4;
      display: block;
      height: 100%;
      padding: 0;
      left: 2px;
      width: 18px;
      background-color: #fff;
      border: 1px solid #CCC;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }
    .toggleSwitch > span span:first-of-type {
      color: #ccc;
      opacity: 1;
      left: 45%;
    }
    .toggleSwitch > span:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50px;
      top: -2px;
      background-color: #fafafa;
      border: 1px solid #ccc;
      border-radius: 30px;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
    }
    .toggleSwitch input:checked ~ a {
      border-color: #fff;
      left: 100%;
      margin-left: -8px;
    }
    .toggleSwitch input:checked ~ span:before {
      border-color: #0097D1;
      box-shadow: inset 0 0 0 30px #0097D1;
    }
    .toggleSwitch input:checked ~ span span:first-of-type {
      opacity: 0;
    }
    .toggleSwitch input:checked ~ span span:last-of-type {
      opacity: 1;
      color: #fff;
    }
    .toggleSwitch.large {
      width: 60px;
      height: 27px;
    }
    .toggleSwitch.large a {
      width: 27px;
    }
    .toggleSwitch.large > span {
      height: 29px;
      line-height: 28px;
    }
    .toggleSwitch.large input:checked ~ a {
      left: 41px;
    }
    .toggleSwitch.large > span span {
      font-size: 1.1em;
    }
    .toggleSwitch.large > span span:first-of-type {
      left: 50%;
    }
    .toggleSwitch.xlarge {
      width: 80px;
      height: 36px;
    }
    .toggleSwitch.xlarge a {
      width: 36px;
    }
    .toggleSwitch.xlarge > span {
      height: 38px;
      line-height: 37px;
    }
    .toggleSwitch.xlarge input:checked ~ a {
      left: 52px;
    }
    .toggleSwitch.xlarge > span span {
      font-size: 1.4em;
    }
    .toggleSwitch.xlarge > span span:first-of-type {
      left: 50%;
    }
  }
*/

.panel_navigator_left{
  background-color: var(--panel_tree_container_background);
  /* border-top: 1px solid #C5C7C7; */
}

.panel_navigator_container{
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 270px;
  top: 136px;
  background-color: var(--panel_tree_container_background);
  /* max-height: calc(100% - 172px); */
  overflow: auto;
  z-index: 2;
}

.panel_navigator_item{
  writing-mode: vertical-rl;
  border-bottom: 2px solid var(--inf-highlight-background-color, #788394);
  width: 32px;
  padding: 12px 8px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  position: relative;
  cursor: pointer;
  min-height: max-content;
}

.disabled_panel_navigator_item{
  writing-mode: vertical-rl;
  border-bottom: 2px solid var(--inf-highlight-background-color, #788394);
  width: 32px;
  padding: 12px 8px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  position: relative;
  cursor: pointer;
  min-height: max-content;
  opacity: 0.5;
}

.panel_navigator_item:hover{
  background-color: rgba(217, 217, 217, 0.5);
}

.arrow_down_panel_navigator {
  width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  
  border-top: 3px solid var(--inf-highlight-background-color, #788394);
  position: absolute;
  top: 0;
  /* left = (width - border-right - border-left)/2*/
  left: 12px;
}

.selected_panel_navigator_item{
  writing-mode: vertical-rl;
  border-bottom: 2px solid var(--inf-highlight-background-color, #788394);
  width: 32px;
  padding: 12px 8px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  position: relative;
  font-weight: bold;
  color: #3A63D4;
  background-color: #E2EFFD;
  cursor: pointer;
  min-height: max-content;
}

.tree_panel_navigator{
  background-color: var(--panel_tree_container_background);
  height: 43px;
  min-height: 43px;
  border-bottom: 1px solid #C5C7C7;
  border-top: 1px solid #C5C7C7;
  display: flex;
  align-items: center;
  width: 32px;
  /* position: fixed;
  z-index: 2; */
}

.right_angle_left_panel_navigator{
  width: 0; 
  height: 0; 
  border-left: 10px solid var(--inf-highlight-background-color, #788394);
  /* border-right: 6px solid transparent;
  border-top: 6px solid transparent; */
  
  border-top: 10px solid transparent;
  position: absolute;
  bottom: 0;
  
}

#panel_scroll_container {
  font-size: 12px;
  border-right: 2px solid var(--inf-highlight-background-color, #788394);
  font-family: 'DM Sans', sans-serif;
  color: #676878;
}

#panel_scroll_container::-webkit-scrollbar {
  width: 0px;
}

/* .selected_panel_navigator_item .arrow_down_panel_navigator {
  animation:  vibrate .5s ease-in-out;
}

@keyframes vibrate {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(0);
  }
} */

.panel_navigator_overflow_container{
  --panel-item-navigator-height: 32px;
  --navigator-sensitivity-x: 24px;
  --navigator-sensitivity-y: 24px;
  /* position: absolute; */
  /* bottom: 0; */
  /* height: 10px; */
}

.panel_navigator_overflow{
  /* display: flex; */
  /* position: relative; */
  display: none;
  padding: var(--navigator-sensitivity-y, 12px) var(--navigator-sensitivity-x, 12px);
}

.panel_navigator_overflow > *{
  background-color: var(--panel_tree_container_background);
}

.panel_navigator_overflow_container:hover .panel_navigator_overflow{
  display: flex;
  position: fixed;
  left: calc(var(--panel_width, 270px) + var(--panel-item-navigator-height, 32px) - var(--navigator-sensitivity-x, 12px));
  /* left: 10; */
  /* bottom: 50px; */
}

.panel_navigator_item_overflow{
  border-right: 2px solid var(--inf-highlight-background-color, #788394);
  height: var(--panel-item-navigator-height, 32px);
  padding: 8px 12px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  /* position: relative; */
  cursor: pointer;
  /* min-height: max-content; */
  min-width: max-content;
}

.selected_panel_navigator_item_overflow{
  border-right: 2px solid var(--inf-highlight-background-color, #788394);
  height: 32px;
  padding: 8px 12px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  /* position: relative; */
  cursor: pointer;
  /* min-height: max-content; */
  font-weight: bold;
  color: #3A63D4;
  background-color: #E2EFFD;
  min-width: max-content;
}

.disabled_panel_navigator_item_overflow{
  border-right: 2px solid var(--inf-highlight-background-color, #788394);
  height: 32px;
  padding: 8px 12px;
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  /* position: relative; */
  cursor: pointer;
  /* min-height: max-content; */
  min-width: max-content;
  opacity: 0.5;
}

.panel_navigator_spread{
  display: flex;
  align-items: center;
  line-height: 10px;
  justify-content: center;
  padding-bottom: 6px;
}

.panel_heading_text{
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  vertical-align: middle;
  font-size: 16px;
}

.view_port_heading{
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
  font-style: italic;
  /* text-decoration: underline; */
  color: #A7A8B2;
}

.view_port_row{
  display: flex;
  /* height: 36px; */
  align-items: center;
}

.view_port_info_container .view_port_row:not(:last-child){
  margin-bottom: 12px;
}

.view_port_property{
  width: 80px;
  text-align: left;
  text-overflow: ellipsis;
  padding-right: 12px;
  font-size: 14px;
}

.view_port_shortcut{
  flex: 1 1;
  display: flex;
  align-items: center;
  /* padding-left: 12px; */
  height: 100%;
}

.view_port_icons {
  padding-right: 12px;
}

.view_port_container {
  margin-top: 8px !important;
  color: #676878;
}

.view_port_text_under_icon{
  font-size: 8px;
}

.view_port_info_text{
  font-size: 12px;
  text-align: left;
  color: #a7a8b3;
}

.panel_empty_state_text_container{
  font-size: 14px;
}

.view_port_text_style_2{
  font-size: 14px;
  /* height: 100%; */
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.view_port_icon_text{
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.panel_info_list{
  background-color:#DBDFE6;
  color:#A7A8B2;
  font-size:12px; 
  font-weight:500;
  padding-right: 12px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 32px;
  text-align: left;
  border-radius: 4px;
}

.view_port_info_container{
  background-color: #EDEFF3;
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.sku_info_toottip_container{
  border-radius: 6px;
  padding: 8px 12px;
  /* width: 400px; */
  background-color: #ffffff;
  color: #0078FF;
}

.inf_animate_ping{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
.penguin_slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.penguin_slider {
  -ms-overflow-style: none;
}

.penguin_slider_arrow_button {
  transition: background-color 0.5s, color 0.5s, opacity 0.5s;
  background-color: white;
  opacity: 0.6;
}

.penguin_slider_arrow_button:hover {
  background-color: black;
  color: white;
  opacity: 0.8;
}

.penguin_slider_arrow_icon {
  /* transform: translateY(15px); */
}

.inf_dummy_carousel_card{
  height: var(--inf-bottom-carousel-card-height, 74px);
  background-color: #31323A;
  border-radius: 2px;
}

.inf_carousel_card{
  cursor: pointer;
  text-align: left;
  height: var(--inf-bottom-carousel-card-height, 74px);
  border-radius: 2px;
  overflow: hidden;
  background-color: white;
  /* border: 1px solid var(--dark-border-color) */
}

.inf_carousel_card_image_container{
  height: 100%;
  /* flex: 1; */
  /* width: 100px; */
  aspect-ratio: 1.5/1;
  overflow: hidden;
  border-right: 1px solid var(--dark-border-color)
}

.inf_carousel_card:hover img{
  scale: 1.05;
  transition: scale 0.5s ease-in-out;
}

.inf_carousel_card_image_container img{
  /* object-fit: contain; */
}

.inf_carousel_group_sku_count{
  visibility: hidden;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--inf-theme-gray-200);
  border: 1px solid var(--light-border-color);
  border-radius: 2px;
}

.inf_carousel_card:hover{
  border: 1px solid #0078ff;
}

.inf_carousel_card:hover .inf_carousel_group_sku_count{
  visibility: visible;
}

.inf_carousel_card_sku_count_placeholder{
  display: flex;
  align-items: center;
  padding: 0px 8px 8px;
  color: #A7A8B2;
}

.inf_carousel_card:hover .inf_carousel_card_sku_count_placeholder{
  display: none;
}

select.inf_carousel_card_sku_selector {
  display: none;
  appearance: none; /* Hide the default arrow */
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none;
  position: relative;
  cursor: pointer;
}

.inf_carousel_card:hover .inf_carousel_card_sku_selector_container{
  position: relative;
  display: inline-block;
  border-top: 1px solid var(--dark-border-color);
}
.inf_carousel_card:hover .inf_carousel_card_sku_selector_container::before{
  content: "\25BE"; /* Custom arrow */
  position: absolute;
  height: 100%;
  width: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #676878;
  right: 0px;
}

.inf_carousel_card_sku_selector_container{
  cursor: pointer;
  background-color: #F5F6F8;
}

.inf_carousel_card_sku_selector_container select {
  padding-right: 24px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inf_carousel_card:hover .inf_carousel_card_sku_selector_container:hover:before{
  color: #0078ff;
  background-color: #E6E9EE
}



.inf_carousel_card:hover .inf_carousel_card_sku_selector{
  display: block;
}

.inf_carousel_action_buttons{
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: white;
  color: #323338;
  font-size: 16px;
  cursor: pointer;
}
.infurnia_logo {
    position: absolute;
    bottom: calc( var(--bottom_status_bar_offset_on_logo) + 4px );
    right:20px;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #00000075;
    pointer-events: none;
}

.infurnia_logo span {
  margin: 0;
  padding-bottom: 0;
  vertical-align: -webkit-baseline-middle;
}

.diagonal_line {
  width: 2px; /* Adjust the width as needed */
  height: 25px;
  border-top: 2px solid #000; /* Adjust the color and thickness as needed */
  border-right: none;
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  transform-origin: 0 100%;
}

.green_hover{
    color: #ffffff;
}

.green_hover:hover{
    color: #1ABB9C;
    text-decoration: none;
}

.gray_hover:hover{
  background-color: #F5F6F8;
}

.design_border_radius{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}x

.blue_hover{
    color: #ffffff;
}

.blue_hover:hover{
    color:rgba(0, 120, 255, 1);
    text-decoration: none;
}

.red_hover:hover{
  color: red;
  text-decoration: none;
}

.black_white_nav_item .nav-link {
    color: black;
}

.active_carosel_tab_css{
    background-color: white !important;
    color:black !important;
    border-radius: 5px 5px 0px 0px !important;
}

/* .carousel_sub_category_tab.active::before{
  content: '';
  position: absolute;
  bottom: -1px;
  height: 2px;
  width: 100%;
  background-color: #494A55;
} */

.carousel_sub_category_tab{
  background-color: inherit;
  color: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 14px;
  padding: 4px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
}

.carousel_sub_category_tab:hover{
  background-color: #36373F80;
}

.carousel_sub_category_tab.active{
  background-color: #36373F;
  font-weight: bold;
  padding: 8px 8px 6px;
  /* border: 1px solid white;
  border-bottom: none; */
}

.infurnia_support_launcher {
    position: fixed;
    top: 100px;
    right:20px;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #00000075;
    cursor: pointer;
}
.version_save_response {
    position: absolute;
    top: 150px;
    right:20px;
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    color: #00000075;
    background-color: white;
    cursor: pointer;
}
.infurnia_support_launcher:hover {
    color: #009688;
}

.black_text {
    color: black;
}

.black_inf_text {
    color: #323338;
}

.elevation_default {
    border-radius: 8px;
    color: black !important;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 10px 5px 10px !important;
}

.elevation_selected {
    border-radius: 8px;
    color: white !important;
    background-color: #0078ff;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 10px 5px 10px !important; 
}

#elevation_cameras_dropdown {
    padding: 0px !important;
    padding-bottom: 0px !important;
    height: 100%;
    display: flex;
    align-items: center;
}

.triangle-topright {
    width: 0;
    height: 0;
    border-top: 24px solid white;
    border-left: 14px solid transparent;
    left: 8px;
    position: relative;
  }

  .triangle-topleft {
    width: 0;
    height: 0;
    border-top: 24px solid white;
    border-right: 14px solid transparent;
    position:relative;
    left: -8px;
  }

  .statusbar_container{
    position: absolute;
    z-index: 5;
    padding: 0px 16px;
    border-radius: 6px;
    box-shadow: 0px 2px 8px 0px #00000040;
    font-size: 12px;
    /* opacity: 0.5; */
    height: 32px;
    /* color: #000000; */
  }

  .canvas_icons_container{
    cursor: pointer;
    background-color: white;
    /* padding: 8px; */
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    color: #676878;
    height: 64px;
    width: 64px;
    box-shadow: 1px 1px 16px 2px #0000000F;
    border: 1px solid #E6E9EE
  }

  .command_line{
    position: absolute;
    top: 140px;
    right: 10px;
    background-color: #E6E9EE;
    border-radius: 4px;
    width: 250px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
  }

  .command_line_text{
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* width:70%; */
    background-color: white;
    font-size: 14px;
    height: 28px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    border: 0;
  }

  .command_valid{
    background-color: white;
    /* width: 20px; */
    height: 28px;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-right: 10px;
  }

  .command_line_options{
    position: absolute;
    top: 180px;
    right: 10px;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    background-color: white;
  }

  .command_line_option{
    background-color: #F5F6F8;
    /* border-radius: 4px; */
    width: 250px;
    height: 36px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 8px;
    padding-left: 12px;
    font-size: 14px;
    cursor: pointer;
  }

  .command_line_option_icon{
    height: 24px;
  }

  .command_line_option:hover{
    background-color: #E6E9EE;
  }

  .command_line_option_text{
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    flex: 1 1;
    min-width: 0px;
    text-align: left;
    display: flex;
  }

.bottom_status_bar {
  --bottom_status_bar_border_color: #A7A8B3 ;
  --bottom_status_bar_height: 32 ;
  font-family: D;
  /* position: absolute; */
  bottom: 0px;
  /* right: 200px; */
  /* background-color: white; */
}

  /* comments */

  .comment_logo{
    position: absolute;
    top: 60px;
    right: 8px;
    /* padding: 12px; */
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .comment_logo.comment_mode{
    background-color: #D3EDFD;
  }

  .comment-pin{
    border-radius: 50% 50% 50% 0%;
    height: 32px;
    width: 32px;
    transform: translateY(-100%);
    background-color: #ffb323;
    color: black;
    border: 1.5px solid black
  }

  /* .comment-pin:hover{
    border-radius:  12px 12px 12px 0px;
    height: auto;
    width: 150px;
    padding: 8px;
  } */

  .comment-preview{
    display: none;
  }

  /* .comment-pin:hover .comment-preview{
    display: block;
  }

  .comment-pin:hover .comment-user{
    display: none;
  }
/* 
  .rbt-token.rbt-token-removeable::after{
    content: "AND";
  } */
/* 
  .rbt-input-wrapper::after{
    content: "AND";
    display: flex;
  } */

  .view_layer_menu .ant-dropdown-menu {
    background-color: #E6E9EE;
    padding: 0px;
    border-radius: 12px;
  }

  .view_layer_menu .ant-dropdown-menu > .ant-dropdown-menu-item-group > .ant-dropdown-menu-item-group-title {
    padding: 0px;
  }

  .view_layer_menu .ant-dropdown-menu > .ant-dropdown-menu-item-group {
    height: calc( 0.75 * var(--inf-canvas-height, 500px) );
    min-width: 240px;
    overflow: auto;
  }


  .dynamic_view_options{
    display: flex;
    align-items: center;
    padding-left: '10px';
    border-left: 1px solid #8E8F9E;
    /* border-top: 1px solid #8E8F9E; */
  }

  #all_views_div_inner > div{
    height: 100%;
  }

  .inactive_view_option:hover{
    background: #E6E9EE;
  }

  .active_view_option{
    background: #E1E9F5;
  }

  .active_view_option:hover{
    background: #C2D2EB;
  }

  .slider_camera_container{
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .camera_rotation{
    position: absolute;
    top: 40px;
  }


  .slider_camera_position {
    -webkit-appearance: none;
    width: 100px;
    height: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    transform: rotateZ(270deg);
    /* flex: 1; */
  }
  
  .slider_camera_position:hover {
    opacity: 1;
  }
  
  .slider_camera_position::-webkit-slider-thumb {
    appearance: none;
    width: 6px;
    height: 6px;
    background:  #0078ff;
    cursor: pointer;
    border-radius: 3px;
  }

  .camera_input_container{
    width: 52px;
    border-radius: 25px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: absolute;
    bottom: 50px;
    right:20px;
    background-color: white;
    border: 1px solid #C5C7CF;
  }

  .camera_image_container{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #CCF4E3;
    border-top: 1px solid #C5C7CF;
    cursor: pointer;
  }

  .camera_entry_image_container{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #CCF4E3;
    position: absolute;
    bottom: 50px;
    right:20px;
    border: 1px solid #C5C7CF;
    cursor: pointer;
  }

  .render-mini-view{
    position: absolute;
    bottom: 60px;
    right:90px;
    width: 300px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #C5C7CF;
    padding: 8px;
    /* padding: 0; */
    /* margin: 0; */
    /* font-size: 15px; */
    /* font-weight: bold; */
    /* color: #00000075; */
    /* pointer-events: none; */
  }

  .rotatable_slider_input:focus{
    border: 1px solid #0078ff !important;
  }

  .rotatable_slider_input{
    text-align: center;
  }

  .single_carousel_sku_parameter{
    width: 240px;
    background-color: white;
    flex-shrink: 0;
    border-right: 1px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    /* justify-content: space-between; */
  }

  .single_carousel_sku_parameter img{
    flex: 1 1;
    min-height: 0px;
    object-fit: contain;
  }

  .display_sku_carousel{
    overflow-x: auto;
    max-width: 100%;
    height: 100%;
  }

  .infurnia_carousel_body{
    background-color: #36373f;
  }

  .carousel_animation{
    transition: 0.5s ease-in-out;
  }

  /* .infurnia_carousel:hover .infurnia_carousel_body {
    background-color: rgba(0, 0, 0, 0.7);
  } */

  .infurnia_carousel_header{
    background-color: var(--inf-theme-gray-800);
    padding-top: 6px;
    padding-bottom: 2px;
  }

  /* .infurnia_carousel:hover .infurnia_carousel_header {
    background-color: rgba(0, 0, 0, 0.8);
  } */

  .infurnia_carousel_header.inactive_carousel_tab{
    background-color: rgba(55, 57, 58, 0.6);
  }

  /* .infurnia_carousel:hover .infurnia_carousel_header.inactive_carousel_tab {
    background-color: rgba(255, 255, 255, 0.8);
  } */


  .carousel_minimized .infurnia_carousel_header{
    background-color: rgba(0, 0, 0, 0.7);
  }

  .carousel_minimized:hover .infurnia_carousel_header {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .carousel_minimized .infurnia_carousel_header.inactive_carousel_tab{
    background-color: rgba(255, 255, 255, 0.6);
  }

  .carousel_minimized:hover .infurnia_carousel_header.inactive_carousel_tab {
    background-color: rgba(255, 255, 255, 0.7);
  }

.navigation_info_share_view{
  border-radius: 4px;
  background-color: #E6E9EE80;
  max-width: 270px;
}

#canvas_container_inner::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

#canvas_container_inner::-webkit-scrollbar-track {
  background-color: #E6E9EE;
  box-shadow: none;
}

/* #canvas_container_inner::-webkit-scrollbar-corner{
  background-color: #E6E9EE;
} */

#canvas_container_inner::-webkit-scrollbar-thumb {
  background-color: #A7A8B2;
  border-radius: 4px;
}

#canvas_container_inner::-webkit-scrollbar-thumb:hover {
  background-color: #676878;
}

.infurnia_ribbon_tab{
  background-color: transparent;
}

.infurnia_ribbon_tab:hover{
  background-color: #F5F6F880;
}

.infurnia_ribbon_tab .triangle-topleft{
  visibility: hidden;
}

.infurnia_ribbon_tab .triangle-topright{
  visibility: hidden;
}

.infurnia_ribbon_tab:hover .triangle-topleft{
  visibility: visible;
}

.infurnia_ribbon_tab:hover .triangle-topright{
  visibility: visible;
}

.bottom_status_bar_default_state{
  width: calc( 100% - 24px );
  background: var(--bottom_status_bar_border_color);
  position: absolute;
  left: 12px;
  bottom: 3px;
  height: 24px;
  font-size: 12px;
  padding: 0px 12px 0px 4px;
  clip-path: polygon(4% 0, 96% 0, 100% 100%, 0% 100%);
  display: flex;
  align-items: center;
}

#infurnia_help_center{
  background: url(/resources/icons/loading_animation.gif) center center no-repeat;
  background-color: white;
}

.table_style { 
	flex-direction: column; 
	width: 100% !important;
} 

.tr_style {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
}

.th_style {
	flex:1 1;
	height:40px;
	padding-left: 12px;
	font-size: 12px;
	font-weight: unset;
	/* border-right: 1px solid #f2f4f6; */
	color:#627386;
}

.td_style_no_scroll {
	flex:1 1;
	min-height: 40px;
	padding-left: 12px;
	font-size: 12px;
	color:#22272e;
	width:100%;
	word-break: break-all;
	border-right: 1px solid #f2f4f6;
	text-transform:capitalize;
}

.table_text_overflow{
	width:100% !important;
	overflow-x: auto !important;
}

.perspective_switcher_container{
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 4px;
  border: 1px solid rgba(228, 228, 227, 1);
  border-radius: 6px;
  height: 36px;
  grid-gap: 4px;
  gap: 4px;
}

.perspective_item{
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 500;
  height: 100%;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.perspective_item:hover{
  background-color: var(--secondary-background-design-navbar, #a7a8b340);
}

.perspective_item.perspective_item_active{
  background-color: #0078ff;
  color: white;
}

.perspective_item.perspective_item_semi_active{
  /* background-color: rgb(0, 120, 255, 0.1); */
  background-color: var(--secondary-background-design-navbar, #a7a8b340);
  /* color: white; */
}
.hover_to_gray2:hover {
    background-color: #f5f6f8;
}

#panel_scroll_container_popup {
    border-right: 1px solid var(--inf-highlight-background-color, #788394);
    font-family: 'DM Sans', sans-serif;
  }
  
#panel_scroll_container_popup::-webkit-scrollbar {
  width: 1.5px;
}

#panel_scroll_container_popup::-webkit-scrollbar-track {
  box-shadow: none;
}

.header_icon_container_panel_popup{
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.header_icon_container_panel_popup img {
  width: 16px;
}

.active_panel_pop_up_search{
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  cursor: pointer;
}

.dropdown_visibility_on_hover:hover .dropdown_item_hide {
    visibility: visible;
}

.dropdown_item_hide {
    visibility: hidden;
}

.inf_single_select_toggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    font-size: 12px;
    width: 100%;
}

.inf_single_select_menu.show{
    max-height: 400px;
    overflow: auto;
    background-color: white;
    padding: 0px 4px 4px 4px;
}

.inf_single_select_menu.dropdown-menu.show{
    max-height: 400px;
    overflow: auto;
    background-color: white;
    padding: 0px 4px 4px 4px;
}

.inf_single_select_item{
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inf_single_select_item:hover{
    background-color: var(--inf-theme-gray-300);
}

.inf_single_select_search_component{
    position: sticky;
    top: 0;
    background: white;
    padding: 4px 0px;
}

.inf_carousel_card_shine{
    /* background: linear-gradient(120deg, #31323A 8%, #60616a 10%, #31323A 33%); */
    animation: carousel_shine 2s ease-in-out infinite;
    /* background-size: 200% 100%; */
}

@keyframes carousel_shine {
   0%, 100% {
    background-color: #31323A;
    /* opacity: 1; */
   }
   50% {
    background-color: #26272C;
    /* opacity: 0.5; */
   }
}
#infurnia-design-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--infurnia-navbar-height, 52px);
  padding: 8px 16px;
  background-color: white;
  color: black;
  border-bottom: 1px solid rgb(230, 233, 238);
  --secondary-background-design-navbar: #a7a8b360;
}

.mes_design_edit_mode #infurnia-design-navbar{
    background-color: #303030;
    color: white;
}

.perspective_switcher_container{
    /* background-color: rgb(167 168 179 / 25%); */
}

#infurnia-design-bread-crumb-bar{
    display: flex;
    align-items: center;
    /* height: 100%; */
    background-color: #F3F4F6;
    border-top-left-radius: 4px;
    border-bottom-left-radius:4px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

 .mes_design_edit_mode #infurnia-design-bread-crumb-bar{
    background-color: #57585c;
}

#infurnia-design-bread-crumb-bar>*{
    background: linear-gradient(to right, #F3F4F6, #EBEDF1) !important;
}

 .mes_design_edit_mode #infurnia-design-bread-crumb-bar>*{
    background: linear-gradient(270deg, #404145 -56.24%, #616368 170.83%) !important;
}


.infurnia-design-navbar-logo{
    max-width: 120px;
    max-height: 100%;
    font-size: 32px;
    object-fit: contain;
}

#mes_work_order_info{
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    max-width: 120px;
}

#infurnia-design-bread-crumb-bar a:hover{
    color: #0078ff;
}
#infurnia-design-bread-crumb-bar a{
    color: inherit;
}

.navbar_item_hover:hover{
    background-color: var(--secondary-background-design-navbar, #a7a8b340);
}

.design-view-navbar-icons{
    cursor: pointer;
    font-size: 24px;
    padding: 4px;
}

.design-view-navbar-icons:hover{
    background-color: var(--secondary-background-design-navbar, #a7a8b340);
}

.design-bread-crumb-nav-item{
    /* background: linear-gradient(to right, transparent, #FFFFFF30); */
    padding: 8px;
    height: 30px;
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    font-size: 12px;
    display: flex;
    align-items: center;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height:3px
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C5C7CF; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #A7A8B3; 
}

.hide {
  display: none;
}

.hide:focus {
  display: block;
}

.hide:focus-within {
  display: block;
}
    
.myDIV:hover .x1 .hide {
  display: block;
}

.myDIV:hover .x1 .x2 .hide {
  display: block;
}
.myDIV:hover{
  background-color: pink;
}

.show_on_hover:hover .hide {
  display: block;
}

.show_on_hover:hover .invisible_icon {
  visibility: visible;
}

.show_on_hover:hover .hidden_dropdown {
  display: flex;
}

.hidden_dropdown {
  display: none;
}

.child{
  display:none;
}
.parent:hover  .child{
  display: block;
}
.table_custom_strip:nth-of-type(odd) {
    background-color: #f9f9f9;
}

/* icons hover green */
.hover_green:hover{
  color:#1ABB9C !important;
}

.hover_blue:hover{
  color:rgba(0, 120, 255, 1) !important;
}

.blue_button{
  height:32px;
  min-width:88px;
  width:auto;
  background-image: linear-gradient(0deg, #007EF4 0%, #0788DE 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20);
  border-radius: 3px;
  font-size:12px;
  border: 0;
  color:white;

}

.red_button{
  margin-right: 12px;
  border: 0;
  height:32px;
  width:88px;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, red 0%, red 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:white;
}

.white_button_auto{
  height:32px;
  min-width:88px;
  width:auto;
  background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  font-size:12px;
  border: 0;
  color:black !important;
}

.white_button{
  margin-right: 12px;
  border: 0;
  height:32px;
  width:88px;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:black !important;
}

/* for material and composite dropdown */
.class_position_unset{
  position: unset;
}

.form-control:focus{
  outline: 0 !important;
  /* border:0 !important; */
  box-shadow: none !important;
}
#input:focus-visible {    
  outline: 0 !important;
}

.tab_pills.active {
  background-color:#E6E9EE !important; 
  color:#676878 !important;
}
.tab_pills{
  color:black !important;
}
.tab_pills:hover{
  color:#676878 !important;
}

/* for Delete notification */

.react-confirm-alert-overlay{
  z-index: 9999 !important;
}


.loader {
  --cube-size: 10px;
  
  position: relative;
  width: calc(var(--cube-size) * 2);
  height: calc(var(--cube-size) * 2);
}

.loader .cube {
  width: var(--cube-size);
  height: var(--cube-size);
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader-cube-1 2.4s .2s cubic-bezier(.72,.01,.01,1) infinite;
}

.loader .cube:nth-of-type(2) {
  background: white;
  right: 0;
  left: auto;
  animation-name: loader-cube-2;
  animation-delay: .4s;
}

.loader .cube:nth-of-type(3) {
  background: white;
  bottom: 0;
  top: auto;
  animation-name: loader-cube-3;
  animation-delay: 0s;
}

@keyframes loader-cube-1 {
  from, to {
    transform: translate(0%);
  }
  25% {
    transform: translateY(100%);
  }
  50% {
    transform: translate(100%, 100%);
  }
  75% {
    transform: translate(100%);
  }
}

@keyframes loader-cube-2 {
  from, to {
    transform: translate(0);
  }
  25% {
    transform: translate(-100%);
  }
  50% {
    transform: translate(-100%, 100%);
  }
  75% {
    transform: translateY(100%);
  }
}

@keyframes loader-cube-3 {
  from, to {
    transform: translate(0);
  }
  25% {
    transform: translate(100%);
  }
  50% {
    transform: translate(100%,-100%);
  }
  75% {
    transform: translateY(-100%);
  }
}

.gooey{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 142px;
  height: 40px;
  margin: -20px 0 0 -71px;
  background: white;
  filter: contrast(20);
}

.gooey .dot{
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
  transform: translateX(0);
  animation: dot 2.8s infinite;
}
.gooey .dots{
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  animation: dots 2.8s infinite;
}

.gooey .dots span{
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  filter: blur(4px);
  background: #000;
  border-radius: 50%;
}

@keyframes dot{
  50%{
    transform: translateX(96px)
  }
}
@keyframes dots{
  50%{
    transform: translateX(-31px)
  }
}

.loading_animation {
  animation: rotation 2s infinite linear;
  height: 20px;
}

@keyframes rotation {
  from{
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.DMSans{
  font-family: 'DM Sans', sans-serif;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10000;
    flex-direction: column;
  }
  
  .image-container {
    position: relative;
    width: 400px; /* Adjust to your desired image width */
    height: auto; /* Adjust to your desired image height */
    overflow: hidden;
  }
  
  /* img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
  
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.7;
    transform-origin: right;
  }
  
  .loading_text{
    color: #676878;
    font-size: 16px;
    font-style: italic;
  }

  .tips-container{
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 12px;
    border-radius: 20px;
    font-style: italic;
    color: #676878;
    background-color: #F5F6F8;
  }
.recent-project-card-footer{
    background-color: var(--inf-theme-gray-600);
}

.recent-project-card-footer .recent-project-card-footer-project-name{
    color: white;
}

.recent-project-card-footer .recent-project-card-footer-secondary-info{
    color: var(--inf-theme-gray-400)
}

.filler_empty_state {
    min-height: 32px;
    background: #F5F6F8;
    border: 1px dashed #C5C7CF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #A7A8B2;
}

.visible_on_hover:hover .invisible {
    visibility: visible !important;
}

.invisible:focus {
    visibility: visible !important;
}
